
import {getStorages} from "./utils/localStorage";
import en_US from "./locales/en_US/common.json"
import zh_CN from "./locales/zh_CN/common.json"
import zh_HK from "./locales/zh_HK/common.json"
import AddOrganization from "./pages/media-pages/systemManagement/Mobile/AddOrganization";
import AddOrganizationDetails from "./pages/media-pages/systemManagement/Mobile/AddOrganizationDetails";

interface LanguageDictionary {
	[key: string]: string;
}

const languageMaps: { [lang: string]: LanguageDictionary } = {
	"en_US": en_US,
	"zh_HK": zh_HK,
};

/**
 * 通用语言
 * @param name
 * @constructor
 */
export const CommonLang = (name: string) => {
	const currentLang = getStorages('i18nextLng');
	const language = languageMaps[currentLang] ?? zh_CN;
	return language[name] || "未知语言";
}



export const antiEpidemicPages = {
	pages: {
		id: "antiEpidemic",
		text: "智慧防疫系统",
		icon: "Extension",
	},

	dashboard: {
		id: "dashboard",
		text: "仪表盘",
		path: "antiEpidemic/dashboard",
		icon: "Home",
		subMenu: null,
	},

	//信息录入管理
	informationInputModel : {
		id: "informationInputModel",
		text: "返蔺录入管理",
		path: "antiEpidemic/informationInputModel",
		icon: "Article",
		subMenu: {
			regulatoryInputList: {
				id: "regulatoryInputList",
				text: "返蔺人员列表",
				path: "antiEpidemic/information-list",
				icon: "ViewArray",
			}
		}
	},

	earlyWarningModel : {
		id: "earlyWarningModel",
		text: "预警管理",
		path: "antiEpidemic/earlyWarningModel",
		icon: "Article",
		subMenu: {
			earlyWarningList: {
				id: "earlyWarningList",
				text: "预警信息列表",
				path: "antiEpidemic/early-warning-list",
				icon: "ViewArray",
			},
		}
	},
	dailyModel: {
		id: "dailyModel",
		text: "日常管理",
		path: "antiEpidemic/dailyModel",
		icon: "Article",
		subMenu: {
			clockInList: {
				id: "clockInList",
				text: "打卡列表",
				path: "antiEpidemic/clock-in-list",
				icon: "ViewArray",
			},
		}
	},
	regionModel:{
		id: "regionModel",
		text: "区域管理",
		path: "antiEpidemic/regionModel",
		icon: "Article",
		subMenu: {
			regionList: {
				id: "regionList",
				text: "区域列表",
				path: "antiEpidemic/region-list",
				icon: "ViewArray",
			},
			riskAreaList: {
				id: "riskAreaList",
				text: "风险区域列表",
				path: "antiEpidemic/risk-area-list",
				icon: "ViewArray",
			},
			agentList: {
				id: "agentList",
				text: "区域代理列表",
				path: "antiEpidemic/agent-list",
				icon: "ViewArray",
			},
		}
	},
	userModel: {
		id: "userModel",
		text: "用户管理",
		path: "antiEpidemic/userModel",
		icon: "Article",
		subMenu: {
			userList: {
				id: "userList",
				text: "人员列表",
				path: "antiEpidemic/user-list",
				icon: "ViewArray",
			},
		}
	},
}

//工位系统
export const stationPages = {
	pages: {
		id: "station",
		text: "智能工位预约系统",
		icon: "Extension",
	},

	dashboard: {
		id: "dashboard",
		text: "仪表盘",
		path: "station/dashboard",
		icon: "Home",
		subMenu: null,
	},

	//工位管理
	stationModel: {
		id: "stationModel",
		text: CommonLang("Station management"),
		path: "station/stationModel",
		icon: "Article",
		subMenu: {
			regionList: {
				id: "regionList",
				text: CommonLang("Region list"),
				path: "station/stationModel/region-list",
				icon: "ViewArray",
			},
			stationList: {
				id: "stationList",
				text: CommonLang("Station list"),
				path: "station/stationModel/station-list",
				icon: "ViewArray",
			},
			labelList: {
				id: "labelList",
				text: CommonLang("Tag list"),
				path: "station/stationModel/label-list",
				icon: "ViewArray",
			},
			stationUpload: {
				id: "stationUpload",
				text: CommonLang("Addition station"),
				path: "station/stationModel/station-upload",
				hide: true,
			},
		}
	},
	//预约管理
	reservationModel: {
		id: "reservationModel",
		text: CommonLang("Appointment management"),
		path: "station/reservationModel",
		icon: "Article",
		subMenu: {
			appointmentView: {
				id: "appointmentView",
				text: CommonLang("Workstation Reservation - View version"),
				path: "station/reservationModel/appointment-view",
				icon: "ViewArray",
			},
			stationReservation: {
				id: "stationReservation",
				text:CommonLang( "Station reservation"),
				path: "station/reservationModel/station-reservation",
				icon: "ViewArray",
			},
			mineReservation: {
				id: "mineReservation",
				text: CommonLang("My appointment"),
				path: "station/reservationModel/mine-reservation",
				icon: "ViewArray",
			},
			reservationRecord: {
				id: "reservationRecord",
				text: CommonLang("Appointment record"),
				path: "station/reservationModel/reservation-record",
				icon: "ViewArray",
			},
			examineList: {
				id: "examineList",
				text: CommonLang("Scheduled audit"),
				path: "station/reservationModel/examine-list",
				icon: "ViewArray",
			},
		}
	},
	//报表管理
	reportModel: {
		id: "reportModel",
		text: CommonLang("Report management"),
		path: "station/reportModel",
		icon: "Article",
		subMenu: {
			attendanceRecord: {
				id: "attendanceRecord",
				text: CommonLang("Sign in record"),
				path: "station/reportModel/attendance",
				icon: "ViewArray",
			},
			usageRecord: {
				id: "usageRecord",
				text: CommonLang("Usage record"),
				path: "station/reportModel/usage",
				icon: "ViewArray",
			},
			memoRecord: {
				id: "memoRecord",
				text: CommonLang("Note record"),
				path: "station/reportModel/memo",
				icon: "ViewArray",
			},
			repairRecord: {
				id: "repairRecord",
				text: CommonLang("Repair record"),
				path: "station/reportModel/repair",
				icon: "ViewArray",
			},
		}
	},

	systemManagement: {
		id: "systemManagement",
		text: CommonLang("System Management"),
		path: "station/systemManagement",
		icon: "Article",
		subMenu: {
			// userManagement: {
			// 	id: "userManagement",
			// 	text: CommonLang("User Management"),
			// 	path: "media/systemManagement/user-management",
			// 	icon: "ViewArray",
			// },
			configurationManagement: {
				id: "configurationManagement",
				text: CommonLang("Configuration Management"),
				path: "station/systemManagement/configuration-management",
				icon: "ViewArray",
			},
		},
	},
}



// 信发
export const mediaPages = {
	pages: {
		id: "media",
		text: "",
		icon: "Extension",
	},

	dashboard: {
		id: "dashboard",
		text: CommonLang("Dashboard"),
		path: "media/dashboard",
		icon: "Home",
		subMenu: null,
	},

	//播放管理
	playManagement : {
		id: "playManagement",
		text: CommonLang("Play Management"),
		path: "media/playManagement",
		icon: "Article",
		subMenu: {
			insertionList: {
				id: "insertionList",
				text: CommonLang("Emergency Insertion"),
				path: "media/playManagement/insertion-list",
				icon: "ViewArray",
			},
			schedulingList: {
				id: "schedulingList",
				text: CommonLang("Program Scheduling"),
				path: "media/playManagement/scheduling-list",
				icon: "ViewArray",
			},
			schedulingLists: {
				id: "schedulingLists",
				text: "测试排程",
				path: "media/playManagement/scheduling-lists",
				icon: "ViewArray",
			},
			defaultPlayList: {
				id: "defaultPlayList",
				text: "默认播放",
				path: "media/playManagement/default-playback-list",
				icon: "ViewArray",
			},
			playHistoryList: {
				id: "playHistoryList",
				text: CommonLang("Play History"),
				path: "media/playHistory/play-history-list",
				icon: "ViewArray",
			},
		}
	},

	//预案管理
	planManagement:{
		id: "planManagement",
		text: "预案管理",
		path: "media/planManagement",
		icon: "Article",
		subMenu: {
			planList: {
				id: "planList",
				text: "预案列表",
				path: "media/programManagement/plan-list",
				icon: "ViewArray",
			},
		}
	},

	//任务
	taskManagement: {
		id: "taskManagement",
		text: CommonLang("Task Management"),
		path: "media/taskManagement",
		icon: "Article",
		subMenu: {
			taskList: {
				id: "taskList",
				text: CommonLang("Task List"),
				path: "media/taskManagement/task-list",
				icon: "ViewArray",
			},
		}
	},

	//节目管理
	programManagement: {
		id: "programManagement",
		text: CommonLang("Program Management"),
		path: "media/programManagement",
		icon: "Article",
		subMenu: {
			sliceUploadPage: {
				id: "sliceUploadPage",
				text: CommonLang("Product List"),
				path: "media/programManagement/slice-upload",
				icon: "ViewArray",
			},
			productList: {
				id: "productList",
				text: CommonLang("Product List"),
				path: "media/programManagement/product-list",
				icon: "ViewArray",
			},
			materialLibrary: {
				id: "materialLibrary",
				text: CommonLang("Material Library"),
				path: "media/programManagement/material-library",
				icon: "ViewArray",
			},
			templateList: {
				id: "templateList",
				text: CommonLang("Template List"),
				path: "media/programManagement/template-list",
				icon: "ViewArray",
			},
			templateAdd: {
				id: "templateAdd",
				text: CommonLang("Add Template"),
				path: "media/programManagement/template-add",
				hide: true,
			},
			templateEdit: {
				id: "templateEdit",
				text: CommonLang("Edit Template"),
				path: "media/programManagement/template-edit",
				hide: true,
			},
			programList: {
				id: "programList",
				text: CommonLang("Program List"),
				path: "media/programManagement/program-list",
				icon: "ViewArray",
			},
			programAdd: {
				id: "programAdd",
				text: CommonLang("Add Program"),
				path: "media/programManagement/program-add",
				hide: true,
			},
			programEdit: {
				id: "programEdit",
				text: CommonLang("Edit Program"),
				path: "media/programManagement/program-edit",
				hide: true,
			},
			issuedDeviceOperate: {
				id: "issuedDeviceOperate",
				text: "选择设备",
				path: "media/programManagement/issued-device-operate",
			},
			issuedDeviceGroupOperate: {
				id: "issuedDeviceGroupOperate",
				text: "选择设备组",
				path: "media/programManagement/issued-device-group-operate",
			},
			programGroupList: {
				id: "programGroupList",
				text: CommonLang("Program Group"),
				path: "media/programManagement/program-group-list",
				icon: "ViewArray",
			},


			monitoringFlow: {
				id: "monitoringFlow",
				text: "监控流",
				path: "media/monitorModel/monitor-list",
				icon: "ViewArray",
			},


		}
	},


	panelPointModel: {
		id: "panelPointModel",
		text: "节点管理",
		path: "media/panelPointModel",
		icon: "Article",
		subMenu: {
			panelPointTypeList: {
				id: "panelPointTypeList",
				text: "节点类型",
				path: "media/panelPointModel/panel-point-type-list",
				icon: "ViewArray",
			},
			panelPointList: {
				id: "panelPointList",
				text: "输入节点",
				path: "media/panelPointModel/panel-point-list",
				icon: "ViewArray",
			},
		}
	},

	//设备
	equipmentManagement: {
		id: "equipmentManagement",
		text: CommonLang("Device Management"),
		path: "media/equipmentManagement",
		icon: "Article",
		subMenu: {
			regionList: {
				id: "regionList",
				text: CommonLang("Region List"),
				path: "media/equipmentManagement/region-list",
				icon: "ViewArray",
			},
			seatsList: {
				id: "seatsList",
				text: "坐席列表",
				path: "media/equipmentManagement/seats-list",
				icon: "ViewArray",
			},
			screenList: {
				id: "screenList",
				text: CommonLang("Screen List"),
				path: "media/equipmentManagement/screen-list",
				icon: "ViewArray",
			},
			equipmentList: {
				id: "equipmentList",
				text: CommonLang("Device List"),
				path: "media/equipmentManagement/equipment-list",
				icon: "ViewArray",
			},
			equipmentLists: {
				id: "equipmentLists",
				text: "设备列表s",
				path: "media/equipmentManagement/equipment-lists",
				icon: "ViewArray",
			},
			equipmentGroupList: {
				id: "equipmentGroupList",
				text: CommonLang("Device Group"),
				path: "media/equipmentManagement/equipment-group-list",
				icon: "ViewArray",
			},
			fixedTimeList: {
				id: "fixedTimeList",
				text: CommonLang("Timing On/Off Machine"),
				path: "media/equipmentManagement/fixed-time-list",
				icon: "ViewArray",
			}
		}
	},
	//人流
	peopleCountingModel: {
		id: "peopleCountingModel",
		text: "人流统计",
		path: "media/peopleCountingModel",
		icon: "Article",
		subMenu: {
			abortionList: {
				id: "abortionList",
				text: "人流列表",
				path: "media/peopleCountingModel/abortion-list",
				icon: "ViewArray",
			},
			abortionSumList: {
				id: "abortionSumList",
				text: CommonLang("Flow Statistics List"),
				path: "media/peopleCountingModel/abortion-sum-list",
				icon: "ViewArray",
			},
		}
	},

	packageModel: {
		id: "packageModel",
		text: CommonLang("Update Package Management"),
		path: "media/packageModel",
		icon: "Article",
		subMenu: {
			packageTypeList: {
				id: "packageTypeList",
				text: CommonLang("Update Package Type"),
				path: "packageModel/package-type-list",
				icon: "ViewArray",
			},
			packageList: {
				id: "packageList",
				text: CommonLang("Update Package List"),
				path: "packageModel/package-list",
				icon: "ViewArray",
			},
			packageUpload: {
				id: "packageUpload",
				text: CommonLang("更新包上传"),
				path: "packageModel/package-upload",
				icon: "ViewArray",
			}
		}
	},

	logModel: {
		id: "logModel",
		text: CommonLang("Log Management"),
		path: "media/logModel",
		icon: "Article",
		subMenu: {
			operationLogManagement: {
				id: "operationLogManagement",
				text: CommonLang("Operation Log"),
				path: "media/systemManagement/operation-log",
				icon: "ViewArray",
			},
			errorLogManagement: {
				id: "errorLogManagement",
				text: CommonLang("Exception Log"),
				path: "media/systemManagement/error-log",
				icon: "ViewArray",
			},
			downloadLogManagement: {
				id: "downloadLogManagement",
				text: CommonLang("设备下载日志"),
				path: "media/systemManagement/download-log",
				icon: "ViewArray",
			},
		}
	},

	systemManagement: {
		id: "systemManagement",
		text: CommonLang("System Management"),
		path: "media/systemManagement",
		icon: "Article",
		subMenu: {
			userManagement: {
				id: "userManagement",
				text: CommonLang("User Management"),
				path: "media/systemManagement/user-management",
				icon: "ViewArray",
			},
			roleManagement: {
				id: "roleManagement",
				text: CommonLang("Role Management"),
				path: "media/systemManagement/role-management",
				icon: "ViewArray",
			},
			menuManagement: {
				id: "menuManagement",
				text: CommonLang("Menu Management"),
				path: "media/systemManagement/menu-management",
				icon: "ViewArray",
			},
			configurationManagement: {
				id: "configurationManagement",
				text: CommonLang("Configuration Management"),
				path: "media/systemManagement/configuration-management",
				icon: "ViewArray",
			},
			departmentModel: {
				id: "departmentModel",
				text: CommonLang("Department Management"),
				path: "media/systemManagement/divisional-management",
				icon: "ViewArray",
			},
			departmentModels: {
				id: "departmentModels",
				text: CommonLang("Department Management"),
				path: "media/systemManagement/divisional-managements",
				icon: "ViewArray",
			},
			organizationModel: {
				id: "organizationModel",
				text: CommonLang("Organizational Management"),
				path: "media/systemManagement/organization",
				icon: "ViewArray",
			},
			addOrganization: {
				id: "addOrganization",
				text: CommonLang("Add Organizational"),
				path: "media/mobile/systemManagement/add-organization",
				icon: "ViewArray",
			},
			addOrganizationDetails: {
				id: "addOrganizationDetails",
				text: CommonLang("Add Organizational Details"),
				path: "media/mobile/systemManagement/add-organization-details",
				icon: "ViewArray",
			},
			operateRoleManagement: {
				id: "roleManagement",
				text: CommonLang("权限操作"),
				path: "media/systemManagement/operate-role-management",
				icon: "ViewArray",
			},
		},
	},

	orderModel: {
		id: "orderModel",
		text: CommonLang("Order Management"),
		path: "media/orderModel",
		icon: "Article",
		subMenu: {
			packageInfo: {
				id: "packageInfo",
				text: CommonLang("Package information"),
				path: "media/orderModel/package-info",
				icon: "ViewArray",
			},
			orderList: {
				id: "orderList",
				text: CommonLang("Order list"),
				path: "media/orderModel/order-list",
				icon: "ViewArray",
			},
			downloadList: {
				id: "downloadList",
				text: CommonLang("Consumption list"),
				path: "media/orderModel/download-list",
				icon: "ViewArray",
			},
		}
	},

	empowerModel: {
		id: "empowerModel",
		text: "授权管理",
		path: "media/empowerModel",
		icon: "Article",
		subMenu: {
			empowerVersionList: {
				id: "empowerVersionList",
				text: "版本列表",
				path: "media/empowerModel/version-list",
				icon: "ViewArray",
			},
			operateEmpowerVersion: {
				id: "operateEmpowerVersion",
				text: "授权版本操作",
				path: "media/empowerModel/operate-version",
				icon: "ViewArray",
			},
			empowerList: {
				id: "empowerList",
				text: "授权列表",
				path: "media/empowerModel/empower-list",
				icon: "ViewArray",
			},
			operateEmpower: {
				id: "operateEmpower",
				text: "授权操作",
				path: "media/empowerModel/operate-empower",
				icon: "ViewArray",
			},
		}
	},

	mobilePhoneScanModel: {
		id: "mobilePhoneScanModel",
		text: "手机端",
		path: "media/mobilePhoneScanModel",
		icon: "Article",
		subMenu: {
			comprehensiveList: {
				id: "ComprehensiveList",
				text: "综合页面",
				path: "media/mobilePhoneScanModel/comprehensive",
				icon: "ViewArray",
			},
		}
	},

	about: {
		id: "about",
		text: CommonLang("About"),
		path: "media/about",
		icon: "Dashboard",
		subMenu: null,
	}
};

// 会议
export const meetingRoomPages = {
	pages: {
		id: "meeting",
		text: "会务系统",
		icon: "Extension",
	},
	dashboard: {
		id: "dashboard",
		text: "仪表盘",
		path: "meeting/dashboard",
		icon: "Home",
		subMenu: null,
	},
	bookingManagement: {
		id: "bookingManagement",
		text: "预约管理",
		path: "meeting/bookingManagement",
		icon: "Article",
		subMenu: {
			appointmentMeeting: {
				id: "appointmentMeeting",
				text: "预约会议",
				path: "meeting/bookingManagement/appointment-meeting",
				icon: "EditCalendar",
				notification: true,
			},
			calendarScheduledMeeting: {
				id: "calendarScheduledMeeting",
				text: "预约会议-新",
				path: "meeting/bookingManagement/calendarScheduledMeeting",
				icon: "EditCalendar",
				notification: true,
			},
			myMeeting: {
				id: "myMeeting",
				text: "我的会议",
				path: "meeting/bookingManagement/my-meeting",
				icon: "AccessAlarm",
			},
			meetingManagement: {
				id: "meetingManagement",
				text: "会议管理",
				path: "meeting/bookingManagement/meeting-management",
				icon: "FileCopy",
			},
			meetingReview: {
				id: "meetingReview",
				text: "会议审核",
				path: "meeting/bookingManagement/meeting-review",
				icon: "FactCheck",
			},
		}
	},
	meetingStatementManagement: {
		id: "meetingStatementManagement",
		text: "会议纪要管理",
		path: "meeting/meetingStatement",
		icon: "ViewArray",
	},
	meetingSignManagement: {
		id: "meetingSignManagement",
		text: "会议签到管理",
		path: "meeting/meetingSignature",
		icon: "ViewArray",
	},
	meetingRoomManagement: {
		id: "meetingRoomManagement",
		text: "会议室管理",
		path: "meeting/meetingRoomManagement",
		icon: "Roofing",
		subMenu: {
			regionalManagement: {
				id: "regionalManagement",
				text: "会议室区域管理",
				path: "meeting/meetingRoomManagement/regional-management",
				icon: "Storefront",
			},
			roomManagement: {
				id: "roomManagement",
				text: "会议室管理",
				path: "meeting/meetingRoomManagement/room-management",
				icon: "Store",
			},
			personnelPreposition: {
				id: 'personnelPreposition',
				text: '会议人员预设',
				path: 'meeting/meetingRoomManagement/personnel-preposition',
				icon: 'ViewArray',
			},
			// tabletTemplate: {
			// 	id: "tabletTemplate",
			// 	text: "显示模板管理",
			// 	path: "meeting/meetingRoomManagement/tablet-template",
			// 	icon: "ViewArray",
			// 	hide: true,
			// },
			productsGrid: {
				id: "productsGrid",
				text: "显示模板管理",
				path: "meeting/meetingRoomManagement/grid",
				icon: "CalendarViewMonth",
			},
			productID: {
				id: "productID",
				text: "显示模板",
				path: "meeting/meetingRoomManagement/product",
				hide: true,
			},
			productID2: {
				id: "productID2",
				text: "显示模板",
				path: "meeting/meetingRoomManagement/product2",
				hide: true,
			},
			inkscreenManagement: {
				id: "inkscreenManagement",
				text: "墨水屏设备管理",
				path: "meeting/meetingRoomManagement/inkscreenManagement",
				icon: "SmartScreen",
			},
			inkscreenTemplateManagement: {
				id: "inkscreenTemplateManagement",
				text: "墨水屏模板管理",
				path: "meeting/meetingRoomManagement/inkscreenTemplateManagement",
				icon: "ViewArray",
			},
			inkscreenTemplateAdd: {
				id: "inkscreenTemplateAdd",
				text: "添加墨水屏模板",
				path: "meeting/meetingRoomManagement/inkscreenTemplateAdd",
				hide: true,
			},
			inkscreenTemplateEdit: {
				id: "inkscreenTemplateEdit",
				text: "编辑墨水屏模板",
				path: "meeting/meetingRoomManagement/inkscreenTemplateEdit",
				hide: true,
			},
		}
	},
	equipmentManagement: {
		id: "equipmentManagement",
		text: "设备资产管理",
		path: "meeting/equipmentManagement",
		icon: "Article",
		subMenu: {
			equipmentList: {
				id: "equipmentList",
				text: "设备列表",
				path: "meeting/equipmentManagement/equipment-list",
				icon: "ViewArray",
			},
			// equipmentGroupList: {
			// 	id: "equipmentGroupList",
			// 	text: "设备组列表",
			// 	path: "meeting/equipmentManagement/equipment-group-list",
			// 	icon: "ViewArray",
			// },
			equipmentTypeList: {
				id: "equipmentTypeList",
				text: "设备类型",
				path: "meeting/equipmentManagement/equipment-type-list",
				icon: "ViewArray",
			},
		}
	},
	meetingRoomLeaseManagement: {
		id: "meetingRoomLeaseManagement",
		text: "会议室租赁管理",
		path: "meeting/meetingRoomLeaseManagement",
		icon: "Article",
		subMenu: {
			meetingSummary: {
				id: "meetingSummary",
				text: "会议纪要",
				path: "meeting/meetingRoomLeaseManagement/meetingSummary",
				icon: "ViewArray",
			},
			meetingRoomRental: {
				id: "meetingRoomRental",
				text: "会议室租赁",
				path: "meeting/meetingRoomLeaseManagement/meetingRoomRental",
				icon: "ViewArray",
			},
			leasedEquipmentManagement: {
				id: "leasedEquipmentManagement",
				text: "租赁设备管理",
				path: "meeting/meetingRoomLeaseManagement/leasedEquipmentManagement",
				icon: "ViewArray",
			},
		}
	},
	systemManagement: {
		id: "systemManagement",
		text: "系统管理",
		path: "meeting/systemManagement",
		icon: "Radio",
		subMenu: {
			userManagement: {
				id: "userManagement",
				text: "用户管理",
				path: "meeting/systemManagement/user-management",
				icon: "Group",
			},
			roleManagement: {
				id: "roleManagement",
				text: "角色管理",
				path: "meeting/systemManagement/role-management",
				icon: "SafetyDivider",
			},
			menuManagement: {
				id: "menuManagement",
				text: "菜单管理",
				path: "meeting/systemManagement/menu-management",
				icon: "Rule",
			},
			configurationManagement: {
				id: "configurationManagement",
				text: "配置管理",
				path: "meeting/systemManagement/configuration-management",
				icon: "Handyman",
			},
			divisionalManagement: {
				id: "divisionalManagement",
				text: "部门管理",
				path: "meeting/systemManagement/divisional-management",
				icon: "Schema",
			},
			organizationManagement: {
				id: "organizationManagement",
				text: "组织管理",
				path: "meeting/systemManagement/organization-management",
				icon: "Podcasts",
				//hide: true,
            },
			inkScreenTest: {
				id: "inkScreenTest",
				text: "墨水屏测试",
				path: "meeting/systemManagement/inkScreenTest",
				icon: "Schema",
			}
		}
	},
	statementManagement: {
		id: "statementManagement",
		text: "报表管理",
		path: "meeting/statementManagement",
		icon: "Storage",
		subMenu: {
			meetingStatement: {
				id: "meetingStatement",
				text: "会议报表",
				path: "meeting/statementManagement/meeting-statement",
				icon: "Tune",
			},
			meetingSignature: {
				id: "meetingSignature",
				text: "会议签到",
				path: "meeting/statementManagement/meeting-signature",
				icon: "WavingHand",
			},
			meetingQuestionnaire: {
				id: "meetingQuestionnaire",
				text: "会议问卷",
				path: "meeting/statementManagement/meeting-questionnaire",
				icon: "ViewArray",
			},
			meetingInvitation: {
				id: "meetingInvitation",
				text: "会议邀请",
				path: "meeting/statementManagement/meeting-invitation",
				icon: "ViewArray",
			},
			meetingVideo: {
				id: "meetingVideo",
				text: "视频会议报表",
				path: "meeting/statementManagement/meeting-video",
				icon: "Tune",
			},
		}
	},
	logManagement: {
		id: "logManagement",
		text: "日志管理",
		path: "meeting/logManagement",
		icon: "Summarize",
		subMenu: {
			actionLog: {
				id: "actionLog",
				text: "行为日志",
				path: "meeting/logManagement/action-log",
				icon: "Subtitles",
			},
		}
	},
	databaseManagement: {
		id: "databaseManagement",
		text: "工具",
		path: "meeting/databaseManagement",
		icon: "KeyboardAlt",
		subMenu: {
			databaseBackup: {
				id: "databaseBackup",
				text: "数据库备份",
				path: "meeting/databaseManagement/database-backup",
				icon: "WifiProtectedSetup",
			},
			databaseReduction: {
				id: "databaseReduction",
				text: "数据库还原",
				path: "meeting/databaseManagement/database-reduction",
				icon: "WrapText",
			},
		}
	},
	repairCenter: {
		id: "repair-center",
		text: "紧急报修",
		path: "repair-center",
		icon: "Announcement",
		subMenu: null,
	},
	about: {
		id: "about",
		text: "关于",
		path: "meeting/about",
		icon: "Dashboard",
		subMenu: null,
	},
};

//访客考勤后台管理
export const attendancePages = {
	pages: {
		id: "attendance",
		text: "访客考勤后台管理",
		icon: "FileCopy",
	},
	meetingList: {
		id: "meetingList",
		text: "会议列表",
		path: "attendance/meetingList",
		icon: "AccessAlarm",
		subMenu: {
			notCompletedMeeting: {
				id: "notCompletedMeeting",
				text: "未完成会议列表",
				path: "attendance/meetingList/notCompletedMeeting",
				icon: "EditCalendar",
				notification: true,
			},
			appointmentMeeting: {
				id: "appointmentMeeting",
				text: "会议预约管理",
				path: "attendance/meetingList/appointmentMeeting",
				icon: "AccessAlarm",
				notification: true,
			},
			archivedMeeting: {
				id: "archivedMeeting",
				text: "已归档会议列表",
				path: "attendance/meetingList/archivedMeeting",
				icon: "WifiProtectedSetup",
			},
			templateMeeting: {
				id: "templateMeeting",
				text: "会议模板列表",
				path: "attendance/meetingList/templateMeeting",
				icon: "CalendarViewMonth",
			},
		}
	},
	signatureManagement:{
		id: "signatureManagement",
		text: "签到管理",
		path: "attendance/signatureManagement",
		icon: "Article",
		subMenu: {
			signatureList: {
				id: "signatureList",
				text: "签到列表",
				path: "attendance/signatureManagement/signatureList",
				icon: "ViewArray",
			},
			signatureStatement: {
				id: "signatureStatement",
				text: "签到报表",
				path: "attendance/signatureManagement/signatureStatement",
				icon: "Tune",
			},
		}
	},
	personalAdministration: {
		id: "personalAdministration",
		text: "人事管理",
		path: "attendance/personalAdministration",
		icon: "Article",
		subMenu: {
			passwordManagement: {
				id: "passwordManagement",
				text: "密码管理",
				path: "attendance/personalAdministration/passwordManagement",
				icon: "EditCalendar",
			},
			personnelManagement: {
				id: "personnelManagement",
				text: "人员管理",
				path: "attendance/personalAdministration/personnelManagement",
				icon: "Group",
			},
			personnelManagement2: {
				id: "personnelManagement2",
				text: "人员管理2",
				path: "attendance/personalAdministration/personnelManagement2",
				icon: "Group",
			},
			userGroupManagement: {
				id: "userGroupManagement",
				text: "用户组管理",
				path: "attendance/personalAdministration/userGroupManagement",
				icon: "Podcasts",
				hide: true,
			},
			workUnitManagement: {
				id: "workUnitManagement",
				text: "工作单位",
				path: "attendance/personalAdministration/workUnitManagement",
				icon: "Podcasts",
				hide: true,
			},
			teamManagement: {
				id: "teamManagement",
				text: "班组管理",
				path: "attendance/personalAdministration/teamManagement",
				icon: "Podcasts",
				hide: true,
			},
			departmentManagement: {
				id: "departmentManagement",
				text: "部门管理",
				path: "attendance/personalAdministration/departmentManagement",
				icon: "Schema",
			},
			administratorList: {
				id: "administratorList",
				text: "管理员列表",
				path: "attendance/personalAdministration/administratorList",
				icon: "AccessAlarm",
			},
			userSearch: {
				id: "userSearch",
				text: "用户搜索",
				path: "attendance/personalAdministration/userSearch",
				icon: "FileCopy",
			},
		}
	},
	systemSetting: {
		id: "systemSetting",
		text: "系统设置",
		path: "attendance/systemSetting",
		icon: "Radio",
		subMenu: {
			permissionManagement: {
				id: "permissionManagement",
				text: "权限管理",
				path: "attendance/systemSetting/permissionManagement",
				icon: "ViewArray",
			},
			addPermission: {
				id: "permissionManagement",
				text: "添加权限",
				path: "attendance/systemSetting/addPermission",
				icon: "ViewArray",
			},
			deviceManagement: {
				id: "deviceManagement",
				text: "设备管理",
				path: "attendance/systemSetting/deviceManagement",
				icon: "ViewArray",
			},
			meetingRoomManagement: {
				id: "meetingRoomManagement",
				text: "会议室管理",
				path: "attendance/systemSetting/meetingRoomManagement",
				icon: "Store",
			},
			menuManagement: {
				id: "menuManagement",
				text: "菜单管理",
				path: "attendance/systemSetting/menuManagement",
				icon: "Rule",
			},
			areaManagement: {
				id: "areaManagement",
				text: "区域管理",
				path: "attendance/systemSetting/areaManagement",
				icon: "ViewArray",
			},
			workforceManagement: {
				id: "workforceManagement",
				text: "排班管理",
				path: "attendance/systemSetting/workforceManagement",
				icon: "ViewArray",
			},
			leaveManagement: {
				id: "leaveManagement",
				text: "请假管理",
				path: "attendance/systemSetting/leaveManagement",
				icon: "ViewArray",
			},
			holidayManagement: {
				id: "holidayManagement",
				text: "节假日管理",
				path: "attendance/systemSetting/holidayManagement",
				icon: "ViewArray",
			},
			timeSetting: {
				id: "timeSetting",
				text: "时段管理",
				path: "attendance/systemSetting/timeSetting",
				icon: "ViewArray",
			},
			userOperationLog: {
				id: "userOperationLog",
				text: "用户操作日志",
				path: "attendance/systemSetting/userOperationLog",
				icon: "Summarize",
			},
			seatManagement: {
				id: "seatManagement",
				text: "座位管理",
				path: "attendance/systemSetting/seatManagement",
				icon: "Roofing",
			},
		}
	},


}


// 无纸化会议系统
export const paperlessPages = {
	pages: {
		id: "paperless",
		text: "无纸化会议系统",
		icon: "FileCopy",
	},
	meetingList: {
		id: "meetingList",
		text: "会议列表",
		path: "paperless/meetingList",
		icon: "AccessAlarm",
		subMenu: {
			notCompletedMeeting: {
				id: "notCompletedMeeting",
				text: "未完成会议列表",
				path: "paperless/meetingList/notCompletedMeeting",
				icon: "EditCalendar",
				notification: true,
				subMenu: {
					meetingDetail: {
						id: "meetingDetail",
						text: "会议详情",
						path: "paperless/meetingList/_details/meetingDetail",
						icon: "EditCalendar",
						notification: true,
					},
					participants: {
						id: "participants",
						text: "参会人员",
						path: "paperless/meetingList/_details/participants",
						icon: "EditCalendar",
						notification: true,
					},
					fileManagement: {
						id: "fileManagement",
						text: "文件管理",
						path: "paperless/meetingList/_details/fileManagement",
						icon: "EditCalendar",
						notification: true,
					},
					videoManagement: {
						id: "videoManagement",
						text: "视频管理",
						path: "paperless/meetingList/_details/videoManagement",
						icon: "EditCalendar",
						notification: true,
					},
					votingManagement: {
						id: "votingManagement",
						text: "表决管理",
						path: "paperless/meetingList/_details/votingManagement",
						icon: "EditCalendar",
						notification: true,
					},
					votingGroupManagement: {
						id: "votingGroupManagement",
						text: "表决组管理",
						path: "paperless/meetingList/_details/votingGroupManagement",
						icon: "EditCalendar",
						notification: true,
					},
					informationManagement: {
						id: "informationManagement",
						text: "消息管理",
						path: "paperless/meetingList/_details/informationManagement",
						icon: "EditCalendar",
						notification: true,
					},
					conferenceRecords: {
						id: "conferenceRecords",
						text: "会议记录",
						path: "paperless/meetingList/_details/conferenceRecords",
						icon: "EditCalendar",
						notification: true,
					},
					voiceSummary: {
						id: "voiceSummary",
						text: "语音纪要",
						path: "paperless/meetingList/_details/voiceSummary",
						icon: "EditCalendar",
						notification: true,
					},
					seatMapArrangement: {
						id: "seatMapArrangement",
						text: "座位图",
						path: "paperless/meetingList/_details/seatMapArrangement",
						icon: "EditCalendar",
						notification: true,
					},
				}
			},
			appointmentMeeting: {
				id: "appointmentMeeting",
				text: "会议预约管理",
				path: "paperless/meetingList/appointmentMeeting",
				icon: "AccessAlarm",
				notification: true,
			},
			archivedMeeting: {
				id: "archivedMeeting",
				text: "已归档会议列表",
				path: "paperless/meetingList/archivedMeeting",
				icon: "WifiProtectedSetup",
			},
			templateMeeting: {
				id: "templateMeeting",
				text: "会议模板列表",
				path: "paperless/meetingList/templateMeeting",
				icon: "CalendarViewMonth",
			},
		}
	},
	personalAdministration: {
		id: "personalAdministration",
		text: "人事管理",
		path: "paperless/personalAdministration",
		icon: "Article",
		subMenu: {
			passwordManagement: {
				id: "passwordManagement",
				text: "密码管理",
				path: "paperless/personalAdministration/passwordManagement",
				icon: "EditCalendar",
			},
			personnelManagement: {
				id: "personnelManagement",
				text: "人员管理",
				path: "paperless/personalAdministration/personnelManagement",
				icon: "Group",
			},
			userGroupManagement: {
				id: "userGroupManagement",
				text: "用户组管理",
				path: "paperless/personalAdministration/userGroupManagement",
				icon: "Podcasts",
				hide: true,
			},
			departmentManagement: {
				id: "departmentManagement",
				text: "部门管理",
				path: "paperless/personalAdministration/departmentManagement",
				icon: "Schema",
			},
			administratorList: {
				id: "administratorList",
				text: "管理员列表",
				path: "paperless/personalAdministration/administratorList",
				icon: "AccessAlarm",
			},
			userSearch: {
				id: "userSearch",
				text: "用户搜索",
				path: "paperless/personalAdministration/userSearch",
				icon: "FileCopy",
			},
		}
	},
	systemSettings: {
		id: "systemSettings",
		text: "系统设置",
		path: "paperless/systemSettings",
		icon: "Radio",
		subMenu: {
			installListManage: {
				id: "installListManage",
				text: "安装包管理",
				path: "paperless/systemSettings/installListManage",
				icon: "ViewArray",
			},
			installationPackageManagement: {
				id: "installationPackageManagement",
				text: "安装包上传",
				path: "paperless/systemSettings/installationPackageManagement",
				icon: "ViewArray",
			},
			permissionManagement: {
				id: "permissionManagement",
				text: "权限管理",
				path: "paperless/systemSettings/permissionManagement",
				icon: "ViewArray",
			},
			roleManagement: {
				id: "roleManagement",
				text: "权限管理",
				path: "paperless/systemSettings/roleManagement",
				icon: "ViewArray",
			},
			operateRoleManagement: {
				id: "operateRoleManagement",
				text: "权限操作",
				path: "paperless/systemSettings/operateRoleManagement",
				icon: "ViewArray",
			},
			addPermission: {
				id: "permissionManagement",
				text: "添加权限",
				path: "paperless/systemSettings/addPermission",
				icon: "ViewArray",
			},
			websiteEditing: {
				id: "websiteEditing",
				text: "网站编辑",
				path: "paperless/systemSettings/websiteEditing",
				icon: "ViewArray",
			},
			deviceManagement: {
				id: "deviceManagement",
				text: "设备管理",
				path: "paperless/systemSettings/deviceManagement",
				icon: "ViewArray",
			},
			apDevicesManagement: {
				id: "apDevicesManagement",
				text: "AP设备管理",
				path: "paperless/systemSettings/apDevicesManagement",
				icon: "ViewArray",
			},
			inkscreenManagement: {
				id: "inkscreenManagement",
				text: "墨水屏设备管理",
				path: "paperless/systemSettings/inkscreenManagement",
				icon: "ViewArray",
			},
			inkscreenTemplateManagement: {
				id: "inkscreenTemplateManagement",
				text: "墨水屏模板管理",
				path: "paperless/systemSettings/inkscreenTemplateManagement",
				icon: "ViewArray",
			},
			inkscreenTemplateAdd: {
				id: "inkscreenTemplateAdd",
				text: "添加墨水屏模板",
				path: "paperless/systemSettings/inkscreenTemplateAdd",
				hide: true,
			},
			inkscreenTemplateEdit: {
				id: "inkscreenTemplateEdit",
				text: "编辑墨水屏模板",
				path: "paperless/systemSettings/inkscreenTemplateEdit",
				hide: true,
			},
			meetingRoomManagement: {
				id: "meetingRoomManagement",
				text: "会议室管理",
				path: "paperless/systemSettings/meetingRoomManagement",
				icon: "Store",
			},
			menuManagement: {
				id: "menuManagement",
				text: "菜单管理",
				path: "paperless/systemSettings/menuManagement",
				icon: "Rule",
			},
			callServiceManagement: {
				id: "callServiceManagement",
				text: "呼叫服务管理",
				path: "paperless/systemSettings/callServiceManagement",
				icon: "Rule",
				hide: true,
			},
			tableStyleManagement: {
				id: "tableStyleManagement",
				text: "桌牌样式管理",
				path: "paperless/systemSettings/tableStyleManagement",
				icon: "Rule",
			},
			votingTemplateManagement: {
				id: "votingTemplateManagement",
				text: "表决模板管理",
				path: "paperless/systemSettings/votingTemplateManagement",
				icon: "ViewArray",
			},
			androidConfigurationPage: {
				id: "androidConfigurationPage",
				text: "android配置页面",
				path: "paperless/systemSettings/androidConfigurationPage",
				icon: "Rule",
				hide: true,
			},
			clientMenuManagement: {
				id: "clientMenuManagement",
				text: "客户端菜单管理",
				path: "paperless/systemSettings/clientMenuManagement",
				icon: "Rule",
			},
			importData: {
				id: "importData",
				text: "导入数据",
				path: "paperless/systemSettings/importData",
				icon: "Rule",
				hide: true,
			},
			userOperationLog: {
				id: "userOperationLog",
				text: "用户操作日志",
				path: "paperless/systemSettings/userOperationLog",
				icon: "Summarize",
			},
			communicationServiceManagement: {
				id: "communicationServiceManagement",
				text: "通信服务管理",
				path: "paperless/systemSettings/communicationServiceManagement",
				icon: "Rule",
			},
			winManagement: {
				id: "winManagement",
				text: "win程序管理",
				path: "paperless/systemSettings/winManagement",
				icon: "ViewArray",
				hide: true,
			},
			seatManagement: {
				id: "seatManagement",
				text: "座位管理",
				path: "paperless/systemSettings/seatManagement",
				icon: "Roofing",
			},
			tableInstallationManagement: {
				id: "tableInstallationManagement",
				text: "桌牌安装包管理",
				path: "paperless/systemSettings/tableInstallationManagement",
				icon: "Rule",
				hide: true,
			},
			historicalConferenceManagement: {
				id: "historicalConferenceManagement",
				text: "历史会议管理",
				path: "paperless/systemSettings/historicalConferenceManagement",
				icon: "ViewArray",
				hide: true,
			},
			waterSignInstallationManagement: {
				id: "waterSignInstallationManagement",
				text: "水牌安装包管理",
				path: "paperless/systemSettings/waterSignInstallationManagement",
				icon: "Rule",
				hide: true,
			},
			largeScreenProgramManagement: {
				id: "largeScreenProgramManagement",
				text: "大屏程序管理",
				path: "paperless/systemSettings/largeScreenProgramManagement",
				icon: "Rule",
				hide: true,
			},
			backupSettings: {
				id: "backupSettings",
				text: "备份设置",
				path: "paperless/systemSettings/backupSettings",
				icon: "ViewArray",
			},
			speechRecognitionConfiguration: {
				id: "speechRecognitionConfiguration",
				text: "语音识别词语配置",
				path: "paperless/systemSettings/speechRecognitionConfiguration",
				icon: "ViewArray",
				hide: true,
			},
		}
	},
	issueManagement:  {
		id: "issueManagement",
		text: "议题管理",
		path: "paperless/issueManagement",
		icon: "Article",
		hide: true,
		subMenu: {
			unauditedTopicList: {
				id: "unauditedTopicList",
				text: "未审核议题列表",
				path: "paperless/issueManagement/unauditedTopicList",
				icon: "EditCalendar",
				hide: true,
			},
			approvedTopicList: {
				id: "approvedTopicList",
				text: "已审核议题列表",
				path: "paperless/issueManagement/approvedTopicList",
				icon: "AccessAlarm",
				hide: true,
			},
		}
	},
	knowledgeManagement: {
		id: "knowledgeManagement",
		text: "资料库",
		path: "paperless/knowledgeManagement",
		icon: "Article",
		subMenu: {
			publicDatabase: {
				id: "publicDatabase",
				text: "公共资料库",
				path: "paperless/knowledgeManagement/publicDatabase",
				icon: "ViewArray",
			}
		}
	},
	fileUpload:  {
		id: "fileUpload",
		text: "多文件上传",
		path: "paperless/fileUpload",
		icon: "Article",
		hide: true,
	},
	userManagement:  {
		id: "userManagement",
		text: "用户管理",
		path: "paperless/userManagement",
		icon: "Group",
	},
	toolManagement:  {
		id: "toolManagement",
		text: "工具管理",
		path: "paperless/toolManagement",
		icon: "KeyboardAlt",
		hide: true,
	},
};

// 管廊
export const pipeGalleryPages = {
	pages: {
		id: "pipeGallery",
		text: "智慧管廊",
		icon: "Extension",
	},
	dashboard: {
		id: "dashboard",
		text: "仪表盘",
		path: "pipeGallery/dashboard",
		icon: "Home",
		subMenu: null,
	},
	largeVisualScreen: {
		id: "largeVisualScreen",
		text: "可视化大屏",
		path: "pipeGallery/largeVisualScreen",
		icon: "Dashboard",
		subMenu: null,
	},
	// systemMonitoring: {
	// 	id: "systemMonitoring",
	// 	text: "系统监控",
	// 	path: "pipeGallery/systemMonitoring",
	// 	icon: "Article",
	// 	subMenu: {
	// 		systemHome: {
	// 			id: "systemHome",
	// 			text: "系统首页",
	// 			path: "pipeGallery/systemMonitoring/systemHome",
	// 			icon: "ViewArray",
	// 		},
	// 		largeVisualScreen: {
	// 			id: "largeVisualScreen",
	// 			text: "可视化大屏",
	// 			path: "pipeGallery/systemMonitoring/largeVisualScreen",
	// 			icon: "ViewArray",
	// 		}
	// 	}
	// },
	centralizedEquipmentMonitoring: {
		id: 'centralizedEquipmentMonitoring',
		text: '设备集中监控',
		path: 'pipeGallery/centralizedEquipmentMonitoring',
		icon: 'Article',
		subMenu: {
			realtimeDataCollection: {
				id: 'realtimeDataCollection',
				text: '实时数据采集',
				path: 'pipeGallery/centralizedEquipmentMonitoring/realtimeDataCollection',
				icon: 'ViewArray',
			},
			historicalDataQuery: {
				id: 'historicalDataQuery',
				text: '历史数据查询',
				path: 'pipeGallery/centralizedEquipmentMonitoring/historicalDataQuery',
				icon: 'ViewArray',
			},
			dataAnalysisReport: {
				id: 'dataAnalysisReport',
				text: '数据分析报表',
				path: 'pipeGallery/centralizedEquipmentMonitoring/dataAnalysisReport',
				icon: 'ViewArray',
			},
			// deviceStatusMonitoring: {
			// 	id: 'deviceStatusMonitoring',
			// 	text: '设备状态监控',
			// 	path: 'pipeGallery/centralizedEquipmentMonitoring/deviceStatusMonitoring',
			// 	icon: 'ViewArray',
			// },
		}
	},
	eventAlarmManagement :{
		id: "eventAlarmManagement",
		text: "事件&告警中心",
		path: "pipeGallery/eventAlarmManagement",
		icon: "Article",
		subMenu: {
			alarmInformation: {
				id: "alarmInformation",
				text: "实时告警信息",
				path: "pipeGallery/equipmentManagement/alarm-information",
				icon: "ViewArray",
			},
			alarmHandlingInformation: {
				id: "alarmHandlingInformation",
				text: "告警处理信息",
				path: "pipeGallery/equipmentManagement/alarm-handling-information",
				icon: "ViewArray",
			},
			alarmProcessingLog: {
				id: "alarmProcessingLog",
				text: "告警处理日志",
				path: "pipeGallery/equipmentManagement/alarm-processing-log",
				icon: "ViewArray",
			},
			equipmentEventList: {
				id: "equipmentEventList",
				text: "实时事件信息",
				path: "pipeGallery/equipmentManagement/equipment-event-list",
				icon: "ViewArray",
			},
		}
	},
	maintenanceManagement: {
		id: "maintenanceManagement",
		text: "维护管理",
		path: "pipeGallery/maintenanceManagement",
		icon: "Article",
		subMenu: {
			maintenancePlan: {
				id: "maintenancePlan",
				text: "维护计划",
				path: "pipeGallery/maintenanceManagement/maintenance-plan",
				icon: "ViewArray",
			},
			maintenanceRecord: {
				id: "maintenanceRecord",
				text: "维护记录",
				path: "pipeGallery/maintenanceManagement/maintenance-record",
				icon: "ViewArray",
			},
			scrapAndScrap: {
				id: "scrapAndScrap",
				text: "报损报废",
				path: "pipeGallery/maintenanceManagement/scrap-and-scrap",
				icon: "ViewArray",
			},


		}
	},

	accountManagement:{
		id: "accountManagement",
		text: "台账管理",
		path: "pipeGallery/accountManagement",
		icon: "Article",
		subMenu: {
			registrationInformation: {
				id: "registrationInformation",
				text: "注册信息",
				path: "pipeGallery/accountManagement/registration-information",
				icon: "ViewArray",
			},
			installationInformation: {
				id: "maintenanceRecord",
				text: "安装信息",
				path: "pipeGallery/accountManagement/maintenance-record",
				icon: "ViewArray",
			},
			debugInformation: {
				id: "debugInformation",
				text: "调试信息",
				path: "pipeGallery/accountManagement/debug-information",
				icon: "ViewArray",
			},
			vendorList: {
				id: "vendorList",
				text: "供应商列表",
				path: "pipeGallery/supplierManagement/vendor-list",
				icon: "ViewArray",
			},
			contractList: {
				id: "contractList",
				text: "合同列表",
				path: "pipeGallery/accountManagement/contract-list",
				icon: "ViewArray",
			}
		}
	},

	equipmentManagement: {
		id: "equipmentManagement",
		text: "设备管理",
		path: "pipeGallery/equipmentManagement",
		icon: "Article",
		subMenu: {
			regionList: {
				id: "regionList",
				text: "区域列表",
				path: "pipeGallery/regionManagement/region-list",
				icon: "ViewArray",
			},
			equipmentList: {
				id: "equipmentList",
				text: "设备列表",
				path: "pipeGallery/equipmentManagement/equipment-list",
				icon: "ViewArray",
			},
			equipmentGroupList: {
				id: "equipmentGroupList",
				text: "设备组列表",
				path: "pipeGallery/equipmentManagement/equipment-group-list",
				icon: "ViewArray",
			},
			equipmentTypeList: {
				id: "equipmentTypeList",
				text: "设备类型",
				path: "pipeGallery/equipmentManagement/equipment-type-list",
				icon: "ViewArray",
			},

			ruleManagement: {
				id: "ruleManagement",
				text: "规则库",
				path: "pipeGallery/equipmentManagement/rule-management",
				icon: "ViewArray",
			},
			deviceConfiguration :{
				id: "deviceConfiguration",
				text: "设备配置信息",
				path: "pipeGallery/equipmentManagement/device-configuration",
				icon: "ViewArray",
			},
			deviceDetails :{
				id: "deviceDetails",
				text: "设备详情信息",
				path: "pipeGallery/equipmentManagement/device-details",
				icon: "ViewArray",
			}
		}
	},

	knowledgeManagement: {
		id: "knowledgeManagement",
		text: "知识库管理",
		path: "pipeGallery/knowledgeManagement",
		icon: "Article",
		subMenu: {
			labelDatabase: {
				id: "labelDatabase",
				text: "标签库",
				path: "pipeGallery/knowledgeManagement/label-database",
				icon: "ViewArray",
			},
			knowledgeList: {
				id: "knowledgeList",
				text: "知识库列表",
				path: "pipeGallery/knowledgeManagement/knowledge-list",
				icon: "ViewArray",
			},
		}
	},
	monitoringManagement: {
		id: "monitoringManagement",
		text: "视频监控",
		path: "pipeGallery/monitoringManagement",
		icon: "Article",
		subMenu: {
			monitorList: {
				id: "monitorList",
				text: "监控列表",
				path: "pipeGallery/monitoringManagement/monitor-list",
				icon: "ViewArray",
			}
		}
	},
	gisMap: {
		id: "gisMap",
		text: "设备地图监控",
		path: "pipeGallery/gisMap",
		icon: "Article",
		subMenu: {
			mapDevice: {
				id: "mapDevice",
				text: "设备地图监控",
				path: "pipeGallery/gisMap/map-device",
				icon: "ViewArray",
			}
		}
	},
	deviceCard: {
		id: 'deviceCard',
		text: '设备卡片监控',
		path: 'pipeGallery/deviceCard',
		icon: 'Article',
		subMenu: {
			equipmentCardMonitoring: {
				id: 'equipmentCardMonitoring',
				text: '设备卡片监控',
				path: 'pipeGallery/deviceCard/equipmentCardMonitoring',
				icon: 'ViewArray',
			},
		}
	},
	twinDigital: {
		id: 'twinDigital',
		text: '三维数字孪生',
		path: 'pipeGallery/twinDigital',
		icon: 'Article',
		subMenu: {
			pipeGallery3d: {
				id: 'pipeGallery3d',
				text: '智慧管廊',
				path: 'pipeGallery/twinDigital/pipeGallery3d',
				icon: 'ViewArray',
			},
		}
	},
	systemManagement: {
		id: "systemManagement",
		text: "系统管理",
		path: "pipeGallery/systemManagement",
		icon: "Article",
		subMenu: {
			userManagement: {
				id: "userManagement",
				text: "用户管理",
				path: "pipeGallery/systemManagement/user-management",
				icon: "ViewArray",
			},
			roleManagement: {
				id: "roleManagement",
				text: "角色管理",
				path: "pipeGallery/systemManagement/role-management",
				icon: "ViewArray",
			},
			menuManagement: {
				id: "menuManagement",
				text: "菜单管理",
				path: "pipeGallery/systemManagement/menu-management",
				icon: "ViewArray",
			},
			configurationManagement: {
				id: "configurationManagement",
				text: "配置管理",
				path: "pipeGallery/systemManagement/configuration-management",
				icon: "ViewArray",
			},
			divisionalManagement: {
				id: "divisionalManagement",
				text: "部门管理",
				path: "pipeGallery/systemManagement/divisional-management",
				icon: "ViewArray",
			}
		}
	},
};

// 能耗
export const energyConsumptionPages = {
	pages: {
		id: "energyConsumption",
		text: "智慧能耗管理系统",
		icon: "Extension",
	},
	dashboard: {
		id: "dashboard",
		text: "仪表盘",
		path: "energyConsumption/dashboard",
		icon: "Home",
		subMenu: null,
	},
	equipmentManagement: {
		id: "equipmentManagement",
		text: "设备管理",
		path: "energyConsumption/equipmentManagement",
		icon: "Article",
		subMenu: {
			equipmentList: {
				id: "equipmentList",
				text: "设备列表",
				path: "energyConsumption/equipmentManagement/equipment-list",
				icon: "ViewArray",
			},
			equipmentGroupList: {
				id: "equipmentGroupList",
				text: "设备组列表",
				path: "energyConsumption/equipmentManagement/equipment-group-list",
				icon: "ViewArray",
			}
		}
	},
	controlManagement: {
		id: "controlManagement",
		text: "物联管理",
		path: "energyConsumption/controlManagement",
		icon: "Article",
		subMenu: {
			regionalManagement: {
				id: "regionalManagement",
				text: "区域管理",
				path: "energyConsumption/controlManagement/regional-management",
				icon: "ViewArray",
			},
			roomManagement: {
				id: "roomManagement",
				text: "房间管理",
				path: "energyConsumption/controlManagement/room-management",
				icon: "ViewArray",
			},
			articleManagement: {
				id: "articleManagement",
				text: "物品管理",
				path: "energyConsumption/controlManagement/article-management",
				icon: "ViewArray",
			},
			articleTypeManagement: {
				id: "articleTypeManagement",
				text: "物品分类",
				path: "energyConsumption/controlManagement/article-type-management",
				icon: "ViewArray",
			},
		}
	},
};

// 中控|数字孪生
export const centralControlPages = {
	pages: {
		id: "centralControl",
		text: "物联中控",
		icon: "Extension",
	},
	dashboard: {
		id: "dashboard",
		text: "仪表盘",
		path: "centralControl/dashboard",
		icon: "Home",
		subMenu: null,
	},
	controlPanel: {
		id: "controlPanel",
		text: "控制中心",
		path: "centralControl/controlPanel",
		icon: "Article",
		subMenu: {
			productsGrid: {
				id: "productsGrid",
				text: "物品控制",
				path: "centralControl/controlPanel/grid",
				icon: "CalendarViewMonth",
			},
			productID: {
				id: "productID",
				text: "物品",
				path: "centralControl/controlPanel/product",
				hide: true,
			},
			digitalTwin: {
				id: "digitalTwin",
				text: "数字孪生",
				path: "centralControl/controlPanel/digital-twin",
				icon: "CalendarViewMonth",
			},
		}
	},
	controlManagement: {
		id: "controlManagement",
		text: "物联管理",
		path: "centralControl/controlManagement",
		icon: "Article",
		subMenu: {
			regionalManagement: {
				id: "regionalManagement",
				text: "区域管理",
				path: "centralControl/controlManagement/regional-management",
				icon: "ViewArray",
			},
			roomManagement: {
				id: "roomManagement",
				text: "房间管理",
				path: "centralControl/controlManagement/room-management",
				icon: "ViewArray",
			},
			articleManagement: {
				id: "articleManagement",
				text: "物品管理",
				path: "centralControl/controlManagement/article-management",
				icon: "ViewArray",
			},
			articleTypeManagement: {
				id: "articleTypeManagement",
				text: "物品分类",
				path: "centralControl/controlManagement/article-type-management",
				icon: "ViewArray",
			},
		}
	},
	equipmentManagement: {
		id: "equipmentManagement",
		text: "设备管理",
		path: "centralControl/equipmentManagement",
		icon: "Article",
		subMenu: {
			equipmentList: {
				id: "equipmentList",
				text: "设备列表",
				path: "centralControl/equipmentManagement/equipment-list",
				icon: "ViewArray",
			},
			equipmentGroupList: {
				id: "equipmentGroupList",
				text: "设备组列表",
				path: "centralControl/equipmentManagement/equipment-group-list",
				icon: "ViewArray",
			},
			equipmentTypeList: {
				id: "equipmentTypeList",
				text: "设备类型",
				path: "centralControl/equipmentManagement/equipment-type-list",
				icon: "ViewArray",
			},
			gatewayList: {
				id: "gatewayList",
				text: "网关列表",
				path: "centralControl/equipmentManagement/gateway-list",
				icon: "ViewArray",
			},
			graffitiList: {
				id: "graffitiList",
				text: "涂鸦账号管理",
				path: "centralControl/equipmentManagement/graffiti-list",
				icon: "ViewArray",
			}
		}
	},
	EvenModel: {
		id: "EvenModel",
		text: "事件管理",
		path: "centralControl/EvenModel",
		icon: "Article",
		subMenu: {
			EvenList: {
				id: "EvenList",
				text: "事件列表",
				path: "centralControl/even/even-list",
				icon: "ViewArray",
			},
			KeyEvenList: {
				id: "KeyEvenList",
				text: "按键事件列表",
				path: "centralControl/even/key-even-list",
				icon: "ViewArray",
			}
		}
	},
	reportForm: {
		id: "reportForm",
		text: "报表管理",
		path: "centralControl/reportForm",
		icon: "Article",
		subMenu: {
			controlHistory: {
				id: "controlHistory",
				text: "控制历史报表",
				path: "centralControl/reportForm/control-history",
				icon: "ViewArray",
			},
			equipmentFailure: {
				id: "equipmentFailure",
				text: "设备故障报表",
				path: "centralControl/reportForm/equipment-failure",
				icon: "ViewArray",
			},
		}
	},
	systemManagement: {
		id: "systemManagement",
		text: "系统管理",
		path: "centralControl/systemManagement",
		icon: "Article",
		subMenu: {
			userManagement: {
				id: "userManagement",
				text: "用户管理",
				path: "centralControl/systemManagement/user-management",
				icon: "ViewArray",
			},
			roleManagement: {
				id: "roleManagement",
				text: "角色管理",
				path: "centralControl/systemManagement/role-management",
				icon: "ViewArray",
			},
			menuManagement: {
				id: "menuManagement",
				text: "菜单管理",
				path: "centralControl/systemManagement/menu-management",
				icon: "ViewArray",
			},
			configurationManagement: {
				id: "configurationManagement",
				text: "配置管理",
				path: "centralControl/systemManagement/configuration-management",
				icon: "ViewArray",
			},
			divisionalManagement: {
				id: "divisionalManagement",
				text: "部门管理",
				path: "centralControl/systemManagement/divisional-management",
				icon: "ViewArray",
			},
			userOperationLog: {
				id: "userOperationLog",
				text: "操作日志",
				path: "centralControl/systemManagement/userOperationLog",
				icon: "ViewArray",
			},
			eventCenter: {
				id: "event-center",
				text: "控制日志",
				path: "centralControl/systemManagement/event-center",
				icon: "Event",
			},
			noticeCenter: {
				id: "notice-center",
				text: "通知中心",
				path: "centralControl/systemManagement/notice-center",
				icon: "Announcement",
			},
			installListManage: {
				id: "installListManage",
				text: "安装包管理",
				path: "centralControl/systemManagement/installListManage",
				icon: "ViewArray",
			},
			installationPackageManagement: {
				id: "installationPackageManagement",
				text: "安装包上传",
				path: "centralControl/systemManagement/installationPackageManagement",
				icon: "ViewArray",
			},
			holidayManagement: {
				id: "holidayManagement",
				text: "假期管理",
				path: "centralControl/systemManagement/holidayManagement",
				icon: "ViewArray",
			},
			doorOpeningRecord: {
				id: "doorOpeningRecord",
				text: "开门记录",
				path: "centralControl/systemManagement/doorOpeningRecord",
				icon: "ViewArray",
			},
		}
	},
	ruleBase: {
		id: "ruleBase",
		text: "规则库",
		path: "centralControl/ruleBase",
		icon: "Article",
		subMenu: {
			// ruleManagement: {
			// 	id: "ruleManagement",
			// 	text: "规则列表",
			// 	path: "centralControl/ruleBase/rule-management",
			// 	icon: "ViewArray",
			// },
			ruleList: {
				id: "ruleList",
				text: "规则列表",
				path: "centralControl/ruleBase/rule-list",
				icon: "ViewArray",
			},
			ruleAdd: {
				id: "ruleAdd",
				text: "添加规则组",
				path: "centralControl/ruleBase/rule-add",
				hide: true,
			},
			sceneManagement: {
				id: "sceneManagement",
				text: "场景列表",
				path: "centralControl/ruleBase/scene-management",
				icon: "ViewArray",
			},
			sightManagement: {
				id: "sightManagement",
				text: "情景列表",
				path: "centralControl/ruleBase/sight-management",
				icon: "ViewArray",
			},
		}
	},

};


// 系统管理
export const systemLayoutPages =
{
	pages: {
		id: "systemLayout",
		text: "系统管理",
		icon: "Extension",
	},
	systemManagement: {
		id: "systemManagement",
		text: "系统管理",
		path: "systemLayout/systemManagement",
		icon: "Article",
		subMenu: {
			configurationManagement: {
				id: "configurationManagement",
				text: "配置管理",
				path: "systemLayout/systemManagement/configuration-management",
				icon: "ViewArray",
			},
			about: {
				id: "about",
				text: "关于",
				path: "systemLayout/systemManagement/about",
				icon: "Dashboard",
				subMenu: null,
			},
		}
	}
}

// 门禁系统
export const doorControlSystemPages = {
	pages: {
		id: "doorControlSystem",
		text: "门禁系统",
		icon: "FileCopy",
	},
	dashboard: {
		id: "dashboard",
		text: "仪表盘",
		path: "doorControlSystem/dashboard",
		icon: "Home",
		subMenu: null,
	},
	personalAdministration: {
		id: "personalAdministration",
		text: "人事管理",
		path: "doorControlSystem/personalAdministration",
		icon: "Article",
		subMenu: {
			personnelManagement: {
				id: "personnelManagement",
				text: "人员管理",
				path: "doorControlSystem/personalAdministration/personnelManagement",
				icon: "Group",
			},
			visitorManagement: {
				id: "visitorManagement",
				text: "访客管理",
				path: "doorControlSystem/personalAdministration/visitorManagement",
				icon: "Group",
			},
			userGroupManagement: {
				id: "userGroupManagement",
				text: "用户组管理",
				path: "doorControlSystem/personalAdministration/userGroupManagement",
				icon: "Podcasts",
				hide: true,
			},
			departmentManagement: {
				id: "departmentManagement",
				text: "部门管理",
				path: "doorControlSystem/personalAdministration/departmentManagement",
				icon: "Schema",
			},
			personnelDelete: {
				id: "personnelDelete",
				text: "人员删除",
				path: "doorControlSystem/personalAdministration/personnelDelete",
				icon: "Schema",
			},
		}
	},
	trafficManagement: {
		id: "trafficManagement",
		text: "通行管理",
		path: "doorControlSystem/trafficManagement",
		icon: "Article",
		subMenu: {
			trafficRecord: {
				id: "trafficRecord",
				text: "通行记录",
				path: "doorControlSystem/trafficManagement/trafficRecord",
				icon: "Group",
			},
			trafficReport: {
				id: "trafficReport",
				text: "通行报表",
				path: "doorControlSystem/trafficManagement/trafficReport",
				icon: "Podcasts",
				hide: true,
			},
		}
	},
	deviceManagement: {
		id: "deviceManagement",
		text: "设备管理",
		path: "doorControlSystem/deviceManagement",
		icon: "Article",
		subMenu: {
			deviceList: {
				id: "deviceList",
				text: "设备列表",
				path: "doorControlSystem/deviceManagement/deviceList",
				icon: "Group",
			},
			deviceGroup: {
				id: "deviceGroup",
				text: "设备组",
				path: "doorControlSystem/deviceManagement/deviceGroup",
				icon: "Podcasts",
			},
			devicePersonnel: {
				id: "devicePersonnel",
				text: "设备人员",
				path: "doorControlSystem/deviceManagement/devicePersonnel",
				icon: "Podcasts",
			},
		}
	},
	systemSetting: {
		id: "systemSetting",
		text: "系统设置",
		path: "doorControlSystem/systemSetting",
		icon: "Radio",
		subMenu: {
			areaManagement: {
				id: "areaManagement",
				text: "区域管理",
				path: "doorControlSystem/systemSetting/areaManagement",
				icon: "ViewArray",
			},
			ruleManagement: {
				id: "ruleManagement",
				text: "规则管理",
				path: "doorControlSystem/systemSetting/ruleManagement",
				icon: "ViewArray",
			},
			operationLog: {
				id: "operationLog",
				text: "操作日志",
				path: "doorControlSystem/systemSetting/operationLog",
				icon: "ViewArray",
			},
			configurationManagement: {
				id: "configurationManagement",
				text: "配置管理",
				path: "doorControlSystem/systemSetting/configurationManagement",
				icon: "ViewArray",
			},
		}
	},
}

// 无感考勤系统
export const stealthAttendanceSystemPages = {
	pages: {
		id: "stealthAttendanceSystem",
		text: "无感考勤系统",
		icon: "FileCopy",
	},
	dashboard: {
		id: "dashboard",
		text: "仪表盘",
		path: "stealthAttendanceSystem/dashboard",
		icon: "Home",
		subMenu: null,
	},
	personalAdministration: {
		id: "personalAdministration",
		text: "人事管理",
		path: "stealthAttendanceSystem/personalAdministration",
		icon: "Article",
		subMenu: {
			personnelManagement: {
				id: "personnelManagement",
				text: "人员管理",
				path: "stealthAttendanceSystem/personalAdministration/personnelManagement",
				icon: "Group",
			},
			userGroupManagement: {
				id: "userGroupManagement",
				text: "人员组管理",
				path: "stealthAttendanceSystem/personalAdministration/userGroupManagement",
				icon: "Podcasts",
				hide: true,
			},
			departmentManagement: {
				id: "departmentManagement",
				text: "部门管理",
				path: "stealthAttendanceSystem/personalAdministration/departmentManagement",
				icon: "Schema",
			},
			// personnelDelete: {
			// 	id: "personnelDelete",
			// 	text: "人员删除",
			// 	path: "stealthAttendanceSystem/personalAdministration/personnelDelete",
			// 	icon: "Schema",
			// },
		}
	},
	attendanceManagement: {
		id: "attendanceManagement",
		text: "考勤管理",
		path: "stealthAttendanceSystem/attendanceManagement",
		icon: "Article",
		subMenu: {
			identificationRecord: {
				id: "identificationRecord",
				text: "识别记录",
				path: "stealthAttendanceSystem/attendanceManagement/identificationRecord",
				icon: "Group",
			},
			attendanceReport: {
				id: "attendanceReport",
				text: "考勤报表",
				path: "stealthAttendanceSystem/attendanceManagement/attendanceReport",
				icon: "Podcasts",
			},
			attendanceSummary: {
				id: "attendanceSummary",
				text: "考勤汇总",
				path: "stealthAttendanceSystem/attendanceManagement/attendanceSummary",
				icon: "Podcasts",
			},
			supplementarySignature: {
				id: "supplementarySignature",
				text: "手动签到",
				path: "stealthAttendanceSystem/attendanceManagement/supplementarySignature",
				icon: "Podcasts",
			},
		}
	},
	systemSetting: {
		id: "systemSetting",
		text: "系统设置",
		path: "stealthAttendanceSystem/systemSetting",
		icon: "Radio",
		subMenu: {
			deviceManagement: {
				id: "deviceManagement",
				text: "设备管理",
				path: "stealthAttendanceSystem/systemSetting/deviceManagement",
				icon: "ViewArray",
			},
			workforceManagement: {
				id: "workforceManagement",
				text: "排班管理",
				path: "stealthAttendanceSystem/systemSetting/workforceManagement",
				icon: "ViewArray",
			},
			timeSlotManagement: {
				id: "timeSlotManagement",
				text: "时段管理",
				path: "stealthAttendanceSystem/systemSetting/timeSlotManagement",
				icon: "ViewArray",
			},
			areaManagement: {
				id: "areaManagement",
				text: "区域管理",
				path: "stealthAttendanceSystem/systemSetting/areaManagement",
				icon: "ViewArray",
			},
			operationLog: {
				id: "operationLog",
				text: "操作日志",
				path: "stealthAttendanceSystem/systemSetting/operationLog",
				icon: "ViewArray",
			},
			configurationManagement: {
				id: "configurationManagement",
				text: "配置管理",
				path: "stealthAttendanceSystem/systemSetting/configurationManagement",
				icon: "ViewArray",
			},
		}
	},
	personalCenter: {
		id: "personalCenter",
		text: "个人中心",
		path: "stealthAttendanceSystem/personalCenter",
		icon: "Article",
		subMenu: {
			personalStatement: {
				id: "personalStatement",
				text: "个人报表",
				path: "stealthAttendanceSystem/personalCenter/personalStatement",
				icon: "Group",
			},
			manualPunch: {
				id: "manualPunch",
				text: "手动打卡",
				path: "stealthAttendanceSystem/personalCenter/manualPunch",
				icon: "Podcasts",
			},
		}
	},
	// deviceManagement: {
	// 	id: "deviceManagement",
	// 	text: "设备管理",
	// 	path: "stealthAttendanceSystem/deviceManagement",
	// 	icon: "Article",
	// 	subMenu: {
	// 		deviceList: {
	// 			id: "deviceList",
	// 			text: "设备列表",
	// 			path: "stealthAttendanceSystem/deviceManagement/deviceList",
	// 			icon: "Group",
	// 		},
	// 		deviceGroup: {
	// 			id: "deviceGroup",
	// 			text: "设备组",
	// 			path: "stealthAttendanceSystem/deviceManagement/deviceGroup",
	// 			icon: "Podcasts",
	// 		},
	// 	}
	// },
}

// 消防监控
export const firefightingPages = {
	pages: {
		id: "firefighting",
		text: "消防监控",
		icon: "Firefighting",
	},
	deviceManagement: {
		id: "deviceManagement",
		text: "设备管理",
		path: "firefighting/deviceManagement",
		icon: "AccessAlarm",
		subMenu: {
			cameraManagement: {
				id: "cameraManagement",
				text: "摄像头管理",
				path: "firefighting/deviceManagement/cameraManagement",
				icon: "EditCalendar",
			},
			smokeSensingManagement: {
				id: "smokeSensingManagement",
				text: "烟感管理",
				path: "firefighting/deviceManagement/smokeSensingManagement",
				icon: "ViewArray",
			},
			playbackDevices: {
				id: "playbackDevices",
				text: "播放设备",
				path: "firefighting/deviceManagement/playbackDevices",
				icon: "ViewArray",
			},
		}
	},
	alarmManagement: {
		id: "alarmManagement",
		text: "告警管理",
		path: "firefighting/alarmManagement",
		icon: "Radio",
		subMenu: {
			alarmRecord: {
				id: "alarmRecord",
				text: "告警记录",
				path: "firefighting/alarmManagement/alarmRecord",
				icon: "ViewArray",
			},
		}
	},
	planManagement: {
		id: "planManagement",
		text: "预案管理",
		path: "firefighting/planManagement",
		icon: "Radio",
		subMenu: {
			mediaLibrary: {
				id: "mediaLibrary",
				text: "媒体库",
				path: "firefighting/planManagement/mediaLibrary",
				icon: "ViewArray",
			},
		}
	},
	systemSetting: {
		id: "systemSetting",
		text: "系统设置",
		path: "firefighting/systemSetting",
		icon: "Radio",
		subMenu: {
			areaManagement: {
				id: "areaManagement",
				text: "区域管理",
				path: "firefighting/systemSetting/areaManagement",
				icon: "ViewArray",
			},
			configurationManagement: {
				id: "configurationManagement",
				text: "配置管理",
				path: "firefighting/systemSetting/configurationManagement",
				icon: "ViewArray",
			},
			userOperationLog: {
				id: "userOperationLog",
				text: "操作日志",
				path: "firefighting/systemSetting/userOperationLog",
				icon: "ViewArray",
			},
		}
	},
}

export const homeMenu = {
	intro: { id: "intro", text: "Intro", path: "#intro", icon: "Vrpano", subMenu: null },
	bootstrap: {
		id: "bootstrap",
		text: "Bootstrap Components",
		path: "#bootstrap",
		icon: "BootstrapFill",
		subMenu: null,
	},
	storybook: {
		id: "storybook",
		text: "Storybook",
		path: "#storybook",
		icon: "CustomStorybook",
		subMenu: null,
	},
	formik: {
		id: "formik",
		text: "Formik",
		path: "#formik",
		icon: "CheckBox",
		subMenu: null,
	},
	apex: {
		id: "apex",
		text: "Apex Charts",
		path: "#apex",
		icon: "AreaChart",
		subMenu: null,
	},
};

export const dashboardMenu = {
	dashboard: {
		id: "dashboard-booking",
		text: "办公空间可视化",
		path: "/",
		icon: "emoji_transportation",
		subMenu: null,
	},
	digitalTwin: {
		id: "digital-twin",
		text: "办公空间数字孪生",
		path: "digital-twin",
		icon: "Apartment",
		subMenu: null,
	},
	eventCenter : {
		id: "event-center",
		text: "事件中心",
		path: "event-center",
		icon: "Event",
		subMenu: null,
	},
	noticeCenter : {
		id: "notice-center",
		text: "通知中心",
		path: "notice-center",
		icon: "Announcement",
		subMenu: null,
	},
	summary: {
		id: "summary",
		text: "Summary",
		path: "summary",
		icon: "sticky_note_2",
		subMenu: null,
	},
};

export const commonText = {
	calenderText: {
		id: "weekly",
		text: "Weekly",
	},
};

export const demoPages = {
	pages: {
		id: "pages",
		text: "Pages",
		icon: "Extension",
	},
	singlePages: {
		id: "singlePages",
		text: "Single Pages",
		path: "single-pages",
		icon: "Article",
		subMenu: {
			boxedSingle: {
				id: "boxedSingle",
				text: "Boxed",
				path: "single-pages/boxed",
				icon: "ViewArray",
			},
			fluidSingle: {
				id: "fluidSingle",
				text: "Fluid",
				path: "single-pages/fluid",
				icon: "ViewDay",
			},
		},
	},
	listPages: {
		id: "listPages",
		text: "List Pages",
		path: "list-pages",
		icon: "Dvr",
		subMenu: {
			listBoxed: {
				id: "listBoxed",
				text: "Boxed List",
				path: "list-pages/boxed-list",
				icon: "ViewArray",
			},
			listFluid: {
				id: "listFluid",
				text: "Fluid List",
				path: "list-pages/fluid-list",
				icon: "ViewDay",
			},
		},
	},
	gridPages: {
		id: "gridPages",
		text: "Grid Pages",
		path: "grid-pages",
		icon: "Window",
		subMenu: {
			gridBoxed: {
				id: "gridBoxed",
				text: "Boxed Grid",
				path: "grid-pages/boxed",
				icon: "ViewArray",
			},
			gridFluid: {
				id: "gridFluid",
				text: "Fluid Grid",
				path: "grid-pages/fluid",
				icon: "ViewDay",
			},
		},
	},
	editPages: {
		id: "editPages",
		text: "Edit Pages",
		path: "edit-pages",
		icon: "drive_file_rename_outline ",
		subMenu: {
			editModern: {
				id: "editModern",
				text: "Modern Edit",
				path: "edit-pages/modern",
				icon: "AutoAwesomeMosaic",
				notification: "primary",
			},
			editBoxed: {
				id: "editBoxed",
				text: "Boxed Edit",
				path: "edit-pages/boxed",
				icon: "ViewArray",
			},
			editFluid: {
				id: "editFluid",
				text: "Fluid Edit",
				path: "edit-pages/fluid",
				icon: "ViewDay",
			},
			editWizard: {
				id: "editWizard",
				text: "Wizard Edit",
				path: "edit-pages/wizard",
				icon: "LinearScale",
			},
			editInCanvas: {
				id: "editInCanvas",
				text: "In Canvas Edit",
				path: "edit-pages/in-canvas",
				icon: "VerticalSplit",
			},
			editInModal: {
				id: "editInModal",
				text: "In Modal Edit",
				path: "edit-pages/in-modal",
				icon: "PictureInPicture",
			},
		},
	},
	pricingTable: {
		id: "pricingTable",
		text: "Pricing Table",
		path: "pricing-table",
		icon: "Local Offer",
	},

	auth: {
		id: "auth",
		text: "Auth Pages",
		icon: "Extension",
	},
	login: {
		id: "login",
		text: "登录",
		path: "auth-pages/login",
		icon: "Login",
	},
	systemPage: {
		id: "systemPage",
		text: "系統操作",
		path: "common/system-page",
		icon: "Home",
		subMenu: null,
	},
	generateKeyPage: {
		id:"editPassword",
		text: "生成激活码",
		path: "auth-pages/generateKeyPage",
		icon: "VpnKey"
	},
	editPassword: {
		id:"editPassword",
		text: "重置密码",
		path: "auth-pages/edit-password",
		icon: "Login"
	},
	configuration: {
		id:"configuration",
		text: "关于",
		path: "auth-pages/configuration",
		icon: "Login"
	},
	empowerPage: {
		id:"empowerPage",
		text: "授权页",
		path: "auth-pages/empower",
		icon: "Login"
	},
	signUp: {
		id: "signUp",
		text: "Sign Up",
		path: "auth-pages/sign-up",
		icon: "PersonAdd",
	},
	employeeID: {
		id: "employeeID",
		text: "employeeID",
		path: "appointment/employee",
		hide: true,
	},
	page404: {
		id: "Page404",
		text: "404 Page",
		path: "auth-pages/404",
		icon: "ReportGmailerrorred",
	},

	app: {
		id: "app",
		text: "Apps",
		icon: "Extension",
	},
	appointment: {
		id: "appointment",
		text: "Appointment",
		path: "appointment",
		icon: "Today",
		subMenu: {
			// dashboard: dashboardMenu.dashboard,
			calendar: {
				id: "calendar",
				text: "预约会议",
				path: "appointment/calendar",
				icon: "EditCalendar",
				notification: true,
			},
			appointmentList: {
				id: "appointmentList",
				text: "我的会议",
				path: "appointment/appointment-list",
				icon: "Event",
			},
			meetingManagement: {
				id: "meetingManagement",
				text: "会议管理",
				path: "appointment/meeting-management",
				icon: "Event",
			},
		},
	},
	meetingRoom: {
		id: "meetingRoom",
		text: "MeetingRoom",
		path: "appointment",
		icon: "Today",
		subMenu: {
			dashboard: dashboardMenu.dashboard,
			calendar: {
				id: "calendar",
				text: "Calendar",
				path: "appointment/calendar",
				icon: "EditCalendar",
				notification: true,
			},
			employeeList: {
				id: "employeeList",
				text: "Employee List",
				path: "appointment/employee-list",
				icon: "PersonSearch",
			},
			employeeID: {
				id: "employeeID",
				text: "employeeID",
				path: "appointment/employee",
				hide: true,
			},
			employee: {
				id: "employee",
				text: "Employee",
				path: "appointment/employee/1",
				icon: "QrCode2",
			},
			appointmentList: {
				id: "appointmentList",
				text: "Appointment List",
				path: "appointment/appointment-list",
				icon: "Event",
			},
		},
	},
	deviceAsset: {
		id: "deviceAsset",
		text: "DeviceAsset",
		path: "appointment",
		icon: "Today",
		subMenu: {
			dashboard: dashboardMenu.dashboard,
			calendar: {
				id: "calendar",
				text: "Calendar",
				path: "appointment/calendar",
				icon: "EditCalendar",
				notification: true,
			},
			employeeList: {
				id: "employeeList",
				text: "Employee List",
				path: "appointment/employee-list",
				icon: "PersonSearch",
			},
			employeeID: {
				id: "employeeID",
				text: "employeeID",
				path: "appointment/employee",
				hide: true,
			},
			employee: {
				id: "employee",
				text: "Employee",
				path: "appointment/employee/1",
				icon: "QrCode2",
			},
			appointmentList: {
				id: "appointmentList",
				text: "Appointment List",
				path: "appointment/appointment-list",
				icon: "Event",
			},
		},
	},
	energyManagement: {
		id: "energyManagement",
		text: "EnergyManagement",
		path: "appointment",
		icon: "Today",
		subMenu: {
			dashboard: dashboardMenu.dashboard,
			calendar: {
				id: "calendar",
				text: "Calendar",
				path: "appointment/calendar",
				icon: "EditCalendar",
				notification: true,
			},
			employeeList: {
				id: "employeeList",
				text: "Employee List",
				path: "appointment/employee-list",
				icon: "PersonSearch",
			},
			employeeID: {
				id: "employeeID",
				text: "employeeID",
				path: "appointment/employee",
				hide: true,
			},
			employee: {
				id: "employee",
				text: "Employee",
				path: "appointment/employee/1",
				icon: "QrCode2",
			},
			appointmentList: {
				id: "appointmentList",
				text: "Appointment List",
				path: "appointment/appointment-list",
				icon: "Event",
			},
		},
	},
	signageManagement: {
		id: "signageManagement",
		text: "SignageManagement",
		path: "appointment",
		icon: "Today",
		subMenu: {
			dashboard: dashboardMenu.dashboard,
			calendar: {
				id: "calendar",
				text: "Calendar",
				path: "appointment/calendar",
				icon: "EditCalendar",
				notification: true,
			},
			employeeList: {
				id: "employeeList",
				text: "Employee List",
				path: "appointment/employee-list",
				icon: "PersonSearch",
			},
			employeeID: {
				id: "employeeID",
				text: "employeeID",
				path: "appointment/employee",
				hide: true,
			},
			employee: {
				id: "employee",
				text: "Employee",
				path: "appointment/employee/1",
				icon: "QrCode2",
			},
			appointmentList: {
				id: "appointmentList",
				text: "Appointment List",
				path: "appointment/appointment-list",
				icon: "Event",
			},
		},
	},
	digitalTwinManagement: {
		id: "digitalTwinManagement",
		text: "DigitalTwinManagement",
		path: "appointment",
		icon: "Today",
		subMenu: {
			dashboard: dashboardMenu.dashboard,
			calendar: {
				id: "calendar",
				text: "Calendar",
				path: "appointment/calendar",
				icon: "EditCalendar",
				notification: true,
			},
			employeeList: {
				id: "employeeList",
				text: "Employee List",
				path: "appointment/employee-list",
				icon: "PersonSearch",
			},
			employeeID: {
				id: "employeeID",
				text: "employeeID",
				path: "appointment/employee",
				hide: true,
			},
			employee: {
				id: "employee",
				text: "Employee",
				path: "appointment/employee/1",
				icon: "QrCode2",
			},
			appointmentList: {
				id: "appointmentList",
				text: "Appointment List",
				path: "appointment/appointment-list",
				icon: "Event",
			},
		},
	},
	projectManagement: {
		id: "projectManagement",
		text: "Project Management",
		path: "project-management",
		icon: "AutoStories",
		subMenu: {
			list: {
				id: "list",
				text: "Projects",
				path: "project-management/list",
				icon: "AutoStories",
			},
			itemID: {
				id: "projectID",
				text: "projectID",
				path: "project-management/project",
				hide: true,
			},
			item: {
				id: "item",
				text: "Project",
				path: "project-management/project/1",
				icon: "Book",
			},
		},
	},
	knowledge: {
		id: "knowledge",
		text: "Knowledge",
		path: "knowledge",
		icon: "AutoStories",
		subMenu: {
			grid: {
				id: "grid",
				text: "Knowledge Grid",
				path: "knowledge/grid",
				icon: "AutoStories",
			},
			itemID: {
				id: "itemID",
				text: "itemID",
				path: "knowledge/item",
				hide: true,
			},
			item: {
				id: "item",
				text: "Item",
				path: "knowledge/item/1",
				icon: "Book",
			},
		},
	},
	sales: {
		id: "sales",
		text: "Sales",
		path: "sales",
		icon: "Store",
		subMenu: {
			dashboard: dashboardMenu.dashboard,
			salesList: {
				id: "products",
				text: "Sales List",
				path: "sales/sales-list",
				icon: "FactCheck",
			},
			productsGrid: {
				id: "productsGrid",
				text: "Products Grid",
				path: "sales/grid",
				icon: "CalendarViewMonth",
			},
			productID: {
				id: "productID",
				text: "productID",
				path: "sales/product",
				hide: true,
			},
			product: {
				id: "product",
				text: "Product",
				path: "sales/product/1",
				icon: "QrCode2",
			},
			transactions: {
				id: "transactions",
				text: "Transactions",
				path: "sales/transactions",
				icon: "PublishedWithChanges",
			},
		},
	},
	crm: {
		id: "crm",
		text: "CRM",
		path: "crm",
		icon: "Contacts",
		subMenu: {
			dashboard: {
				id: "dashboard",
				text: "CRM Dashboard",
				path: "crm/dashboard",
				icon: "RecentActors",
			},
			customersList: {
				id: "customersList",
				text: "Customers",
				path: "crm/customers",
				icon: "PersonSearch",
			},
			customerID: {
				id: "customerID",
				text: "customerID",
				path: "crm/customer",
				hide: true,
			},
			customer: {
				id: "customer",
				text: "Customer",
				path: "crm/customer/1",
				icon: "Badge",
			},
			// sales: {
			// 	id: "sales",
			// 	text: "Sales",
			// 	path: "crm/sales",
			// 	icon: "Storefront",
			// },
			// invoiceID: {
			// 	id: "invoiceID",
			// 	text: "invoiceID",
			// 	path: "crm/invoice",
			// 	hide: true,
			// },
			// invoice: {
			// 	id: "invoice",
			// 	text: "Invoice",
			// 	path: "crm/invoice/1",
			// 	icon: "Receipt",
			// },
		},
	},
	chat: {
		id: "chat",
		text: "Chat",
		path: "chat",
		icon: "Forum",
		subMenu: {
			withListChat: {
				id: "withListChat",
				text: "With List",
				path: "chat/with-list",
				icon: "Quickreply",
			},
			onlyListChat: {
				id: "onlyListChat",
				text: "Only List",
				path: "chat/only-list",
				icon: "Dns",
			},
		},
	},
};

export const layoutMenu = {
	layoutTypes: {
		id: "layoutTypes",
		text: "Page Layout Types",
	},
	blank: {
		id: "blank",
		text: "Blank",
		path: "page-layouts/blank",
		icon: "check_box_outline_blank ",
	},
	excelMap: {
		id: "excelMap",
		text: "ExcelMap",
		path: "/excelMap",
		icon: "check_box_outline_blank ",
	},
	pageLayout: {
		id: "pageLayout",
		text: "Page Layout",
		path: "page-layouts",
		icon: "BackupTable",
		subMenu: {
			headerAndSubheader: {
				id: "headerAndSubheader",
				text: "Header & Subheader",
				path: "page-layouts/header-and-subheader",
				icon: "ViewAgenda",
			},
			onlyHeader: {
				id: "onlyHeader",
				text: "Only Header",
				path: "page-layouts/only-header",
				icon: "ViewStream",
			},
			onlySubheader: {
				id: "onlySubheader",
				text: "Only Subheader",
				path: "page-layouts/only-subheader",
				icon: "ViewStream",
			},
			onlyContent: {
				id: "onlyContent",
				text: "Only Content",
				path: "page-layouts/only-content",
				icon: "WebAsset",
			},
		},
	},
	asideTypes: {
		id: "asideTypes",
		text: "Aside Types",
		path: "aside-types",
		icon: "Vertical Split",
		subMenu: {
			defaultAside: {
				id: "defaultAside",
				text: "Default Aside",
				path: "aside-types/default-aside",
				icon: "ViewQuilt",
			},
			minimizeAside: {
				id: "minimizeAside",
				text: "Minimize Aside",
				path: "aside-types/minimize-aside",
				icon: "View Compact",
			},
		},
	},
};

export const componentsMenu = {
	bootstrap: {
		id: "bootstrap",
		text: "Bootstrap",
		icon: "Extension",
	},
	content: {
		id: "content",
		text: "Content",
		path: "content",
		icon: "format_size",
		subMenu: {
			typography: {
				id: "typography",
				text: "Typography",
				path: "content/typography",
				icon: "text_fields",
			},
			images: {
				id: "images",
				text: "Images",
				path: "content/images",
				icon: "Image ",
			},
			tables: {
				id: "tables",
				text: "Tables",
				path: "content/tables",
				icon: "table_chart",
			},
			figures: {
				id: "figures",
				text: "Figures",
				path: "content/figures",
				icon: "Photo Library ",
			},
		},
	},
	forms: {
		id: "forms",
		text: "Forms",
		path: "forms",
		icon: "CheckBox",
		notification: "success",
		subMenu: {
			formGroup: {
				id: "formGroup",
				text: "Form Group",
				path: "forms/form-group",
				icon: "Source",
			},
			formControl: {
				id: "formControl",
				text: "Form Controls",
				path: "forms/form-controls",
				icon: "Create",
			},
			select: {
				id: "select",
				text: "Select",
				path: "forms/select",
				icon: "Checklist",
			},
			checksAndRadio: {
				id: "checksAndRadio",
				text: "Checks & Radio",
				path: "forms/checks-and-radio",
				icon: "CheckBox",
			},
			range: {
				id: "range",
				text: "Range",
				path: "forms/range",
				icon: "HdrStrong",
			},
			inputGroup: {
				id: "inputGroup",
				text: "Input Group",
				path: "forms/input-group",
				icon: "PowerInput",
			},
			validation: {
				id: "validation",
				text: "Validation",
				path: "forms/validation",
				icon: "VerifiedUser",
			},
			wizard: {
				id: "wizard",
				text: "Wizard",
				path: "forms/wizard",
				icon: "LinearScale",
			},
		},
	},
	components: {
		id: "components",
		text: "Component",
		path: "components",
		icon: "Extension",
		notification: "success",
		subMenu: {
			accordion: {
				id: "accordion",
				text: "Accordion",
				path: "components/accordion",
				icon: "ViewDay",
			},
			alert: {
				id: "alert",
				text: "Alert",
				path: "components/alert",
				icon: "Announcement",
			},
			badge: {
				id: "badge",
				text: "Badge",
				path: "components/badge",
				icon: "Vibration",
			},
			breadcrumb: {
				id: "breadcrumb",
				text: "Breadcrumb",
				path: "components/breadcrumb",
				icon: "AddRoad",
			},
			button: {
				id: "button",
				text: "Button",
				path: "components/button",
				icon: "SmartButton",
			},
			buttonGroup: {
				id: "buttonGroup",
				text: "Button Group",
				path: "components/button-group",
				icon: "Splitscreen",
			},
			card: {
				id: "card",
				text: "Card",
				path: "components/card",
				icon: "Crop32",
			},
			carousel: {
				id: "carousel",
				text: "Carousel",
				path: "components/carousel",
				icon: "RecentActors",
			},
			// Close
			collapse: {
				id: "collapse",
				text: "Collapse",
				path: "components/collapse",
				icon: "UnfoldLess",
			},
			dropdowns: {
				id: "dropdowns",
				text: "Dropdowns",
				path: "components/dropdowns",
				icon: "Inventory",
			},
			listGroup: {
				id: "listGroup",
				text: "List Group",
				path: "components/list-group",
				icon: "ListAlt",
			},
			modal: {
				id: "modal",
				text: "Modal",
				path: "components/modal",
				icon: "PictureInPicture",
			},
			navsTabs: {
				id: "navsTabs",
				text: "Navs & Tabs",
				path: "components/navs-and-tabs",
				icon: "PivotTableChart",
			},
			// Navbar
			offcanvas: {
				id: "offcanvas",
				text: "Offcanvas",
				path: "components/offcanvas",
				icon: "VerticalSplit",
			},
			pagination: {
				id: "pagination",
				text: "Pagination",
				path: "components/pagination",
				icon: "Money",
			},
			popovers: {
				id: "popovers",
				text: "Popovers",
				path: "components/popovers",
				icon: "Assistant",
			},
			progress: {
				id: "progress",
				text: "Progress",
				path: "components/progress",
				icon: "HourglassTop",
			},
			scrollspy: {
				id: "scrollspy",
				text: "Scrollspy",
				path: "components/scrollspy",
				icon: "KeyboardHide",
			},
			spinners: {
				id: "spinners",
				text: "Spinners",
				path: "components/spinners",
				icon: "RotateRight",
			},
			table: {
				id: "table",
				text: "Table",
				path: "components/table",
				icon: "TableChart",
			},
			toasts: {
				id: "toasts",
				text: "Toasts",
				path: "components/toasts",
				icon: "RotateRight",
			},
			tooltip: {
				id: "tooltip",
				text: "Tooltip",
				path: "components/tooltip",
				icon: "Assistant",
			},
		},
	},
	utilities: {
		id: "utilities",
		text: "Utilities",
		path: "utilities",
		icon: "Support",
		subMenu: {
			api: {
				id: "api",
				text: "API",
				path: "utilities/api",
				icon: "Api",
			},
			background: {
				id: "background",
				text: "Background",
				path: "utilities/background",
				icon: "FormatColorFill",
			},
			borders: {
				id: "borders",
				text: "Borders",
				path: "utilities/borders",
				icon: "BorderStyle",
			},
			colors: {
				id: "colors",
				text: "Colors",
				path: "utilities/colors",
				icon: "InvertColors",
			},
			display: {
				id: "display",
				text: "Display",
				path: "utilities/display",
				icon: "LaptopMac",
			},
			flex: {
				id: "flex",
				text: "Flex",
				path: "utilities/flex",
				icon: "SettingsOverscan",
			},
			float: {
				id: "float",
				text: "Float",
				path: "utilities/float",
				icon: "ViewArray",
			},
			interactions: {
				id: "interactions",
				text: "Interactions",
				path: "utilities/interactions",
				icon: "Mouse",
			},
			overflow: {
				id: "overflow",
				text: "Overflow",
				path: "utilities/overflow",
				icon: "TableRows",
			},
			position: {
				id: "position",
				text: "Position",
				path: "utilities/position",
				icon: "Adjust",
			},
			shadows: {
				id: "shadows",
				text: "Shadows",
				path: "utilities/shadows",
				icon: "ContentCopy",
			},
			sizing: {
				id: "sizing",
				text: "Sizing",
				path: "utilities/sizing",
				icon: "Straighten",
			},
			spacing: {
				id: "spacing",
				text: "Spacing",
				path: "utilities/spacing",
				icon: "SpaceBar",
			},
			text: {
				id: "text",
				text: "Text",
				path: "utilities/text",
				icon: "TextFields",
			},
			verticalAlign: {
				id: "vertical-align",
				text: "Vertical Align",
				path: "utilities/vertical-align",
				icon: "VerticalAlignCenter",
			},
			visibility: {
				id: "visibility",
				text: "Visibility",
				path: "utilities/visibility",
				icon: "Visibility",
			},
		},
	},
	extra: {
		id: "extra",
		text: "Extra Library",
		icon: "Extension",
		path: undefined,
	},
	icons: {
		id: "icons",
		text: "Icons",
		path: "icons",
		icon: "Grain",
		notification: "success",
		subMenu: {
			icon: {
				id: "icon",
				text: "Icon",
				path: "icons/icon",
				icon: "Lightbulb",
			},
			material: {
				id: "material",
				text: "Material",
				path: "icons/material",
				icon: "Verified",
			},
		},
	},
	charts: {
		id: "charts",
		text: "Charts",
		path: "charts",
		icon: "AreaChart",
		notification: "success",
		subMenu: {
			chartsUsage: {
				id: "chartsUsage",
				text: "General Usage",
				path: "charts/general-usage",
				icon: "Description",
			},
			chartsSparkline: {
				id: "chartsSparkline",
				text: "Sparkline",
				path: "charts/sparkline",
				icon: "AddChart",
			},
			chartsLine: {
				id: "chartsLine",
				text: "Line",
				path: "charts/line",
				icon: "ShowChart",
			},
			chartsArea: {
				id: "chartsArea",
				text: "Area",
				path: "charts/area",
				icon: "AreaChart",
			},
			chartsColumn: {
				id: "chartsColumn",
				text: "Column",
				path: "charts/column",
				icon: "BarChart",
			},
			chartsBar: {
				id: "chartsBar",
				text: "Bar",
				path: "charts/bar",
				icon: "StackedBarChart",
			},
			chartsMixed: {
				id: "chartsMixed",
				text: "Mixed",
				path: "charts/mixed",
				icon: "MultilineChart",
			},
			chartsTimeline: {
				id: "chartsTimeline",
				text: "Timeline",
				path: "charts/timeline",
				icon: "WaterfallChart",
			},
			chartsCandleStick: {
				id: "chartsCandleStick",
				text: "Candlestick",
				path: "charts/candlestick",
				icon: "Cake",
			},
			chartsBoxWhisker: {
				id: "chartsBoxWhisker",
				text: "Box Whisker",
				path: "charts/box-whisker",
				icon: "SportsMma",
			},
			chartsPieDonut: {
				id: "chartsPieDonut",
				text: "Pie & Donut",
				path: "charts/pie-donut",
				icon: "PieChart",
			},
			chartsRadar: {
				id: "chartsRadar",
				text: "Radar",
				path: "charts/radar",
				icon: "BrightnessLow",
			},
			chartsPolar: {
				id: "chartsPolar",
				text: "Polar",
				path: "charts/polar",
				icon: "TrackChanges",
			},
			chartsRadialBar: {
				id: "chartsRadialBar",
				text: "Radial Bar",
				path: "charts/radial-bar",
				icon: "DonutLarge",
			},
			chartsBubble: {
				id: "chartsBubble",
				text: "Bubble",
				path: "charts/bubble",
				icon: "BubbleChart",
			},
			chartsScatter: {
				id: "chartsScatter",
				text: "Scatter",
				path: "charts/scatter",
				icon: "ScatterPlot",
			},
			chartsHeatMap: {
				id: "chartsHeatMap",
				text: "Heat Map",
				path: "charts/heat-map",
				icon: "GridOn",
			},
			chartsTreeMap: {
				id: "chartsTreeMap",
				text: "Tree Map",
				path: "charts/tree-map",
				icon: "AccountTree",
			},
		},
	},
	notification: {
		id: "notification",
		text: "Notification",
		path: "notifications",
		icon: "NotificationsNone",
	},
	hooks: {
		id: "hooks",
		text: "Hooks",
		path: "hooks",
		icon: "Anchor",
	},
};

export const productsMenu = {
	companyA: { id: "companyA", text: "Company A", path: "grid-pages/products", subMenu: null },
	companyB: { id: "companyB", text: "Company B", path: "/", subMenu: null },
	companyC: { id: "companyC", text: "Company C", path: "/", subMenu: null },
	companyD: { id: "companyD", text: "Company D", path: "/", subMenu: null },
};
