import {
	Button as Buttons,
	Form,
	Input,
	InputNumber, Segmented,
	TreeSelect
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	addEmpowerKey,
	addEventLogKey,
	addFlowPoolKey,
	addOperateLogKey,
	addOrganizationKey,
	addUserKey,
	editOrganizationKey,
	editUserKey,
	getEmpowerKey,
	getFlowPoolKey,
	getOrganizationKey,
	getUserKey
} from "../../../../api/commonKey";
import AuthContext from '../../../../contexts/authContext';
import { mediaPages } from '../../../../menu';
import {
	curTimeStamp, getField,
	getRecordFind,
	getRecords,
	mediaEventLogData,
	setBuildSubsetValue,
	uuid2,
} from "../../../../utils";
import {echoPutBacks, messageWithIcon} from "../../../../api/common";
import i18n from "i18next";
import {setStorages} from "../../../../utils/localStorage";
import { useNavigate } from 'react-router-dom';

const AddOrganizationFrom = (parameter?: any | null ) => {

	console.log(parameter)
	// 组织编号
	const orgNumId = parameter.orgNum


	// 路由控制
	const navigate = useNavigate();

	// 用户登录信息
	const {userData} = useContext(AuthContext);

	//多语言
	const {t} = useTranslation('common');

	// 添加组织-组织详情信息-表单
	const [isPowerModalOpen] = useState<any>({
		windowCode: false,
		operateCode: "Add"
	});
	const [formPower] = Form.useForm(); //form表单组件
	const [onLoadingStart, setLoadingStart] = useState(false)
	const onFinishPermission = async (values: any) => {
		setLoadingStart(true)
		try {
			let response: any,
				OrgNum: any = values?.org_num ?? 0;
			if (isPowerModalOpen.operateCode !== "Edit") {
				let orgNumCode = true;

				//循环，避免组织编号重复
				while (orgNumCode) {
					OrgNum = Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000;
					let addOrgNum = await getRecords(getOrganizationKey, {
						filter: [
							`org_num,eq,${OrgNum}`
						]
					})
					orgNumCode = addOrgNum.length >= 1
				}

				response = await addOrganizationKey({
					...values,
					oid: uuid2(0, 16),
					org_num: OrgNum,
					flow: (values.flow * 1024 * 1024),
					create_time: curTimeStamp(),
					update_time: curTimeStamp()
				})

				// 获取-用户昵称
				const userInfo = await getRecordFind(getUserKey, {
					filter: [
						`username,eq,${values.username}`
					],
					include: "nickname",
					size: 1
				})
				if (userInfo) {
					values.username = values.username.toString().substring(0, values.username.toString().length - 6) + OrgNum.toString()
				}

				// 创建下级用户
				await onFinishUser({
					org_num: OrgNum,
					username: values.username,
					real_name: t('System Administrator'),
					phone: values.mobile,
					department_id: 0,
					groupid: 0,
					view_permissions: 1
				})

				//创建-许可证
				await installEmpower(values.pid, OrgNum)

				// 添加-默认的流量
				await installFlowPool(values.pid, OrgNum)
			} else {
				response = await editOrganizationKey(values.org_num, {
					...values,
					flow: (values.flow * 1024 * 1024),
					update_time: curTimeStamp()
				})
			}
			setLoadingStart(false)

			// 操作日志
			await addOperateLogKey({
				oid: uuid2(32, 16),
				org_num: orgNumId,
				uid: userData.id,
				system_id: 1,
				is_state: Boolean(response) ? 1 : 0,
				describe: `${isPowerModalOpen.operateCode !== "Edit" ? t('Add') : (t('Edit') + "-" + t('Organization') + "【" + values.org_name + "】")}`,
				create_time: curTimeStamp()
			})

			if (!Boolean(response)) return messageWithIcon(
				"子级组织创建失败",
				"error",
			)

			// 存储用户添加的组织信息
			setStorages("organization_details_" + orgNumId, JSON.stringify({
				...values,
				status: response,
				org_num: OrgNum,
				parent_name: getField(allOrganizationInfo, "org_num", Number(values.pid), "org_name") ?? "",
				create_time: curTimeStamp()
			}))

			// 跳转
			navigate(`/${mediaPages.systemManagement.subMenu.addOrganizationDetails.path}/${orgNumId}`)
		} catch (error: any) {
			setLoadingStart(false)

			//记录 错误日志
			await addEventLogKey({
				...mediaEventLogData(),
				name: mediaPages.systemManagement.subMenu.addOrganization.text,
				desc: error.message,
				creator: userData.id,
				org_num: orgNumId,
				operation: isPowerModalOpen.operateCode
			})

			return messageWithIcon(
				error.message,
				"error",
			);
		}
	}

	// 人员列表
	const [allUserInfo, setAllUserInfo] = useState<any>([])
	const getAllUserDate = async (
		orgNum: any,
		departmentId?: number | null,
		groupId?: number | null,
		username?: string | null,
		realName?: string | null,
		phone?: string | null
	) => {
		try {
			let whereArr = [
				`org_num,eq,${orgNum}`,
				`ascription,in,-1,1`
			];

			if (departmentId) {
				whereArr.push(`department_id,eq,${departmentId}`);
			}

			if (groupId) {
				whereArr.push(`groupid,eq,${groupId}`);
			}

			if (username) {
				whereArr.push(`username,cs,${username}`);
			}

			if (realName) {
				whereArr.push(`real_name,cs,${realName}`);
			}

			if (phone) {
				whereArr.push(`phone,eq,${phone}`);
			}

			let response = await getRecords(getUserKey, {
				filter: whereArr,
				order: "last_login_time,desc&last_time,desc",
				include: "org_num,id",
				size: 5000
			})

			setAllUserInfo(response);
		} catch (error: any) {
			//记录 错误日志
			await addEventLogKey({
				...mediaEventLogData(),
				name: mediaPages.systemManagement.subMenu.addOrganization.text,
				desc: error.message,
				creator: userData.id,
				org_num: orgNumId,
				operation: "Query"
			})

			return messageWithIcon(
				error.message,
				"error",
			);
		}
	};


	// ${t('Organization')}列表
	const [allOrganizationInfo, setAllOrganizationInfo] = useState<any>([]);
	const [allOrganizationTreeInfo, setAllOrganizationTreeInfo] = useState<any>([])
	const [allSuperiorOrganizationInfo, setSuperiorOrganizationInfo] = useState<any>([])
	const getOrganizationData = async (pointOut: boolean = false) => {
		try {
			let Where = [
				`org_num,eq,${orgNumId}`
			];

			let orgData: any = await getRecords(getOrganizationKey, {
				filter1: Where,
				filter2: [
					`pid,eq,${orgNumId}`
				],
				order: "pid,asc&update_time,desc",
				size: 5000
			})

			if (pointOut) {
				echoPutBacks({
					records: orgData
				})
			}

			if (orgData.length >= 1) {
				// 异步执行
				await Promise.all(orgData.map(async (i: any, k: number) => {
					orgData[k].id = i.org_num;

					// 统计人员
					orgData[k].numberArr = await getRecords(getUserKey, {
						filter: [
							`org_num,eq,${i.org_num}`,
							`ascription,in,-1,1`
						],
						order: "last_login_time,desc&last_time,desc",
						include: "id,username,department_id,groupid,real_name,phone,view_permissions,last_login_time"
					})
					orgData[k].numberSum = orgData[k].numberArr.length;
				}))
				setAllOrganizationInfo(orgData);

				// 获取-上级组织
				const onCurrentOrg = orgData.find((i: any) => i.org_num === Number(orgNumId))
				const onParentOrgData: any = await getRecordFind(getOrganizationKey, {
					filter2: [
						`org_num,eq,${onCurrentOrg.pid}`
					],
					size: 1
				})

				if (onParentOrgData) {
					setSuperiorOrganizationInfo([{
						pid: onParentOrgData.pid,
						value: onParentOrgData.org_num,
						label: onParentOrgData.org_name,
						disabled: false
					}])
				}
			}
		} catch (error: any) {
			//记录 错误日志
			await addEventLogKey({
				...mediaEventLogData(),
				name: mediaPages.systemManagement.subMenu.addOrganization.text,
				desc: error.message,
				creator: userData.id,
				org_num: orgNumId,
				operation: "Query"
			})

			return messageWithIcon(
				error.message,
				"error",
			);
		}
	}
	useEffect(() => {
		// 获取-组织列表
		getOrganizationData().then(_ => {
		})

		//	eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allUserInfo]);


	//处理${t('Department')}树
	const allGroupingTree = (id: number = -1) => {
		let resultInfo: any = [
			{
				pid: -1,
				value: 0,
				label: `${t('Top Level')}${t('Organization')}`,
			}
		];

		allOrganizationInfo.map((i: any) =>
			resultInfo.push({
				pid: i.pid,
				value: i.org_num,
				label: i.org_name,
				disabled: i.org_num === id
			})
		)

		// 获取-上级组织
		const onCurrentOrg = allOrganizationInfo.find((i: any) => i.org_num === Number(orgNumId))
		setAllOrganizationTreeInfo(setBuildSubsetValue([
			...resultInfo,
			...allSuperiorOrganizationInfo
		], 'children', Number(onCurrentOrg?.pid) ?? 0));
	}
	useEffect(() => {
		if (allOrganizationInfo.length >= 1) {
			allGroupingTree()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allOrganizationInfo, allSuperiorOrganizationInfo])


	//创建-授权许可证
	const installEmpower = async (org_num_pid: number, org_num: number) => {
		try {
			const empowerData: any = await getRecordFind(getEmpowerKey, {
				filter: [
					`org_num,eq,${org_num_pid}`
				],
				include: "menu,start_time,end_time,encryption_key,system_code,container_id",
				size: 1
			})
			if (empowerData) {
				await addEmpowerKey({
					...empowerData,
					oid: uuid2(32, 16),
					uid: userData.id,
					org_num: org_num,
					system_version_id: null,
					create_time: curTimeStamp(),
					last_time: curTimeStamp()
				})
			}
		} catch (e: any) {
			//记录 错误日志
			await addEventLogKey({
				...mediaEventLogData(),
				name: mediaPages.systemManagement.subMenu.addOrganization.text,
				desc: e.message,
				creator: userData.id,
				org_num: orgNumId,
				operation: "Add"
			})
		}
	}

	const installFlowPool = async (_: number, org_num: number) => {
		try {
			// 添加-流量池
			const flowPoolInfo: any = await getRecordFind(getFlowPoolKey, {
				filter: [
					`org_num,eq,${org_num}`,
				],
				size: 1
			})
			if (!flowPoolInfo) {
				await addFlowPoolKey({
					org_num: org_num,
					total_traffic: (1024 * 1024 * 1024),
					flow: (1024 * 1024 * 1024),
					create_time: curTimeStamp(),
					last_time: curTimeStamp(),
				})
			}
		} catch (error: any) {
			//记录 错误日志
			await addEventLogKey({
				...mediaEventLogData(),
				name: mediaPages.systemManagement.subMenu.addOrganization.text,
				desc: error.message,
				creator: userData.id,
				org_num: orgNumId,
				operation: "Add",
			});

			return messageWithIcon(
				error.message,
				"error",
			);
		}
	}


	// 获取-指定组织
	useEffect(() => {
		// 重置表单
		formPower.resetFields()
		formPower.setFieldsValue({
			sort: 1,
			pid: orgNumId,
			status: 1,
			language: i18n.language ?? "zh_CN"
		})

		// 获取-用户列表
		getAllUserDate(orgNumId).then(() => {})

		//	eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orgNumId, i18n]);


	// 过滤${t('Search')}
	const filterTreeNode = (inputValue: string, treeNode: any) => {
		return treeNode.label.indexOf(inputValue) > -1;
	}

	//${t('User')}${t('Operation')}
	const [isUserOpen] = useState<any>({
		windowCode: false,
		operateCode: "Add"
	});

	const onFinishUser = async (values: any) => {
		try {
			values["last_time"] = curTimeStamp();
			values["department_id"] = (values["department_id"] !== "8ce305c7d16d4b05") ? values["department_id"] : 0;

			let response: any;
			if (isUserOpen.operateCode !== "Edit") {
				const userInfo = await getRecordFind(getUserKey, {
					filter: [
						`username,eq,${values.username}`
					],
					size: 1
				})
				if (userInfo) {
					values.username = values.username.toString().substring(0, values.username.toString().length - 6) + values.org_num.toString()
				}

				response = await addUserKey({
					...values,
					oid: uuid2(32, 16),
					token: uuid2(32, 16),
					ascription: 1,
					datetime: curTimeStamp()
				})
			} else {
				response = await editUserKey(values.org_num, values)
			}

			//${t('Operation')}日志
			await addOperateLogKey({
				oid: uuid2(32, 16),
				org_num: orgNumId,
				uid: userData.id,
				system_id: 1,
				is_state: Boolean(response) ? 1 : 0,
				describe: `${isUserOpen.operateCode !== "Edit" ? t('Add') : (t('Edit') + "-" + t('User') + "【" + values.real_name + "】")}`,
				create_time: curTimeStamp()
			})
		} catch (error: any) {
			//记录 错误日志
			await addEventLogKey({
				...mediaEventLogData(),
				name: mediaPages.systemManagement.subMenu.addOrganization.text,
				desc: error.message,
				creator: userData.id,
				org_num: orgNumId,
				operation: isUserOpen.operateCode
			})

			return messageWithIcon(
				error.message,
				"error",
			);
		}
	}


	// 语言icon
	const ChineseIcon: React.FC = () => (
		<svg d="1727506551665" className="icon" viewBox="0 0 1070 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
			 p-id="1978" width="16" height="16">
			<path
				d="M232.582 358.133c12.288 36.33 32.59 67.851 60.905 95.633 24.042-26.18 42.207-58.235 53.96-95.633H232.583z"
				p-id="1979" fill="#2c2c2c"></path>
			<path
				d="M981.615 143.36H532.836L507.192 6.055H90.468C44.522 6.055 7.123 43.453 7.123 89.4v708.43c0 45.946 37.399 83.344 83.345 83.344h379.86l-30.453 137.305h541.74c45.947 0 83.345-37.398 83.345-83.344v-708.43c0-45.947-37.398-83.345-83.345-83.345zM415.833 564.358c-49.152-18.165-89.756-41.139-122.346-67.852-34.192 30.453-76.933 52.892-126.62 66.783l-17.096-28.316c48.618-12.822 89.222-32.055 121.277-59.303-33.124-33.658-56.097-72.66-68.92-117.003h-46.48v-32.056h121.277c-7.48-13.89-17.096-27.247-28.316-40.07l32.056-11.753c11.22 14.425 21.37 31.522 30.453 51.29h115.935v32.055h-46.481c-14.96 45.946-36.33 84.413-64.646 115.4 31.522 25.11 71.057 45.947 117.538 63.043l-17.631 27.782zM1023.288 934.6c0 22.974-18.7 41.673-41.673 41.673H492.232l20.837-95.633h156.538l-89.222-497.397-0.534 2.671-3.74-19.767 1.069 0.534-32.59-181.649h437.56c22.973 0 41.672 18.7 41.672 41.673V934.6z"
				p-id="1980" fill="#2c2c2c"></path>
			<path
				d="M684.566 541.384h114.866v-30.453H684.566v-60.905h122.346v-30.453H648.771V638.62h162.95v-30.453H684.565v-66.783zM924.45 475.67c-9.616 0-18.164 1.603-26.178 5.877-7.48 3.74-14.96 9.617-20.837 17.096v-18.699h-34.727V638.62h34.727v-95.633c1.069-12.822 5.343-22.439 12.823-29.384 6.41-5.877 13.89-9.083 22.439-9.083 24.041 0 35.795 12.823 35.795 39.001v94.565h34.727v-97.77c1.069-43.275-19.233-64.646-58.769-64.646z"
				p-id="1981" fill="#2c2c2c"></path>
		</svg>
	);

	//
	const EnglishIcon: React.FC = () => (
		<svg d="1727506469358" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
			 p-id="1674" width="16" height="16">
			<path
				d="M579.584 478.208h-9.728l37.376 207.872c24.576-8.704 47.104-22.016 67.072-39.424-20.48-24.576-36.864-52.224-49.664-81.408l39.424-5.12c10.752 22.016 23.04 41.984 37.376 58.88 29.184-35.328 51.2-82.432 67.072-141.312l-188.928 0.512z m148.992 168.448c22.528 19.456 48.128 33.792 76.8 42.496l17.92 5.632-10.752 38.4-17.92-5.632c-34.304-10.752-66.048-28.672-93.184-52.736-25.088 22.528-54.784 39.936-87.04 50.688l25.088 140.288H489.984l-19.968 91.648H939.52c22.016 0 39.936-17.92 39.936-39.936V238.592c0-22.016-17.92-39.936-39.936-39.936H520.192l31.232 174.08-1.024-0.512 3.584 18.944 0.512-2.56 8.704 49.664h96.768v-39.936h74.752v39.936h124.928v39.936h-52.224c-17.408 70.144-44.032 126.464-78.848 168.448zM449.024 865.28H84.992c-44.032 0-79.872-35.84-79.872-79.872v-678.4c0-44.544 35.84-79.872 79.872-79.872h399.36l24.576 131.584h430.08c44.032 0 79.872 35.84 79.872 79.872v678.912c0 44.032-35.84 79.872-79.872 79.872H420.352l28.672-132.096z m-188.416-307.2v-41.984H181.76v-61.952h73.216v-41.472H181.76v-52.736h78.848v-41.472H135.68V558.08h124.928z m192.512 0V438.272c0-21.504-5.12-38.4-14.848-50.176-9.728-11.776-24.576-17.408-44.032-17.408-11.264 0-21.504 2.048-30.208 6.656s-15.872 11.776-20.48 20.48h-2.56l-6.144-23.552h-34.816V558.08h45.056v-86.528c0-21.504 3.072-36.864 8.704-46.592 5.632-9.216 14.848-13.824 27.648-13.824 9.216 0 15.872 3.072 20.48 9.728 4.096 6.656 6.656 16.384 6.656 29.696v107.52h44.544z"
				p-id="1675" fill="#2c2c2c"></path>
		</svg>
	)

	// 语言切换
	const onChangeLanguage = (e: any) => {
		setStorages("i18nextLng", e)

		// 页面刷新
		window.location.reload();
	}


	return (
		<div style={{
			padding: "20px"
		}}>
			<Form
				form={formPower}
				layout='vertical'
				name='dynamic_form_complex'
				onFinish={onFinishPermission}
				autoComplete='off'
			>
				{
					parameter.multilingual ? (
						<>
							<Form.Item name="language" style={{
								display: "flex",
								justifyContent: "flex-end"
							}}>
								<Segmented
									onChange={onChangeLanguage}
									options={[
										{label: '中文', value: 'zh_CN', icon: <ChineseIcon/>},
										{label: 'English', value: "en_US", icon: <EnglishIcon/>},
									]}
								/>
							</Form.Item>
						</>
					) : (
						<></>
					)
				}

				<Form.Item name='org_name' label={t('Name')}
						   rules={[{required: true, message: `${t('Name')}，${t('Can Not Be Empty')}！`}]}>
					<Input/>
				</Form.Item>

				{
					isPowerModalOpen.operateCode !== "Edit" ? (
						<>
							<Form.Item name='pid' label={t('Superior')} rules={[{
								required: true,
								message: `${t('Name')}，${t('Can Not Be Empty')}！`
							}]}>
								<TreeSelect
									allowClear
									showSearch
									disabled={isPowerModalOpen.operateCode !== "Add"}
									style={{width: '100%'}}
									dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
									placeholder={t('Please Select')}
									treeDefaultExpandAll
									filterTreeNode={filterTreeNode}
									treeData={allOrganizationTreeInfo}
								/>
							</Form.Item>
							<Form.Item name='username' label={`${t('Administrator')}-${t('Account')}`}
									   rules={[{
										   required: true,
										   message: `${t('Administrator')}-${t('Account')}，${t('Can Not Be Empty')}！`
									   }]}>
								<Input/>
							</Form.Item>
						</>
					) : (
						<>
							<Form.Item name="org_num" hidden={true}></Form.Item>
						</>
					)
				}

				<Form.Item name='mobile' label={t('Contact number')}
						   rules={[{required: true, message: `${t('Phone')}，${t('Can Not Be Empty')}！`}]}>
					<InputNumber controls={false} style={{width: '100%'}}/>
				</Form.Item>

				<Form.Item name='mailbox' label={t('Mailbox')}>
					<Input/>
				</Form.Item>

				<Form.Item name='address' label={t('Address')}>
					<Input.TextArea/>
				</Form.Item>

				<Form.Item>
					<Buttons
						loading={onLoadingStart}
						type="primary"
						htmlType="submit"
						className='w-100'
					>
						{t('Save')}
					</Buttons>
				</Form.Item>
			</Form>
		</div>
	);
};

export default AddOrganizationFrom;
