import request from '../utils/noVoucher';
import Config from '../config';
import axios from 'axios';
import showNotification from '../components/extras/showNotification';
import { getStorage } from '../utils/sessionStorage';
import requestKey from "../utils/secretKey";

const server = Config.siteAddress;

const on_off = Config.on_off ? Config.on_off : false;


//===================================已废弃方法，旧版仍在使用================================================
/**
 * 文件上传
 * @param data
 */
export async function uploadFile(data: any) {
	return request({
		url: server + '/api.php/uploadMaterialFile',
		method: 'post',
		data: data,
	});
}

/**
 * 异常提示
 * @param abnormalText
 * @param typeCode // "default" || "info" || "warning" || "success" || "danger",
 * @constructor
 */
export function abnormalPrompt(abnormalText: string, typeCode?: string | 'warning') {
	showNotification(
		'异常提示', // String, HTML or Component
		abnormalText, // String, HTML or Component
		typeCode,
	);

	//return abnormalPrompt(error.message,"danger")
	return false;
}

/**
 * 消息提示
 * @param response
 */
export function echoPutBack(response: any) {
	let TipsCode = "warning"

	if (response >= 1) {
		TipsCode = "success"
	}

	showNotification(
		'提示信息', // String, HTML or Component
		`操作成功，获取${response}条数据！`, // String, HTML or Component
		TipsCode, // "default" || "info" || "warning" || "success" || "danger",
	);
}


//======================================================================================





//==================================鉴权=================================================

export function authentication(e: any) {
	return e.view_permissions >= 1;



}





//====================================================================================
/**
 * 安装
 */
export async function installTypeInfo(key: number) {
	let FieldValue = null;
	let installTypeData = [
		{
			value: 1,
			label: 'Android程序',
		},
		{
			value: 2,
			label: 'Windows程序',
		},
	];

	installTypeData.map((item: any) => {
		if (item.value === key) {
			FieldValue = item["label"];
		}
	});

	return FieldValue ?? '- - -';
}



//上传媒体文件
export async function uploadMaterialFile(data: any) {
	return request({
		url: server + '/api/v5/program_model/uploadMaterialFile',
		method: 'post',
		data,
	});
}

//=====================================================================
/**
 * 获取通知
 * @param query
 */
export async function getNotice(query: any) {
	return request({
		// url: server + "/api/commons/notice_model/getNotice",
		url: server + '/api.php/records/think_notice',
		method: 'get',
		params: query,
	});
}

//===================================================================

export async function getPlayHistory(query: any) {
	return request({
		// url: server + "/api/v5/play_history_model/getPlayHistory",
		url: server + '/api.php/records/think_play_history?database=media',
		method: 'get',
		params: query,
	});
}

export async function addPlayHistory(data: any) {
	return request({
		// url: server + "/api/v5/task_model/getTasks",
		url: server + '/api.php/records/think_play_history?database=media',
		method: 'post',
		data,
		params: {
			database: 'media',
		},
	});
}

export async function delPlayHistory(id: any) {
	return request({
		// url: server + "/api/v5/play_history_model/delPlayHistory",
		url: server + '/api.php/records/think_play_history/' + id,
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: {
			database: 'media',
		},
	});
}

//====================================================================
/**
 * 获取事件
 * @param query
 */
export async function getEvent(query: any) {
	return request({
		url: server + '/api/commons/event_model/getEvent',
		method: 'get',
		params: query,
	});
}





//===============================任务管理=========================================

//任务列表
export async function getTask(query: any) {
	return request({
		// url: server + "/api/v5/task_model/getTasks",
		url: server + '/api.php/records/think_task?database=media',
		method: 'get',
		params: query,
	});
}

//添加任务
export async function addTask(data: any) {
	return request({
		// url: server + "/api/v5/task_model/addTask",
		url: server + '/api.php/records/think_task',
		method: 'post',
		data,
		params: {
			database: 'media',
		},
		headers: {
			'Content-Type': 'application/json'
		},
	});
}

// 删除任务
export async function delTask(id: any) {
	return request({
		url: server + '/api.php/records/think_task/' + id,
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: {
			database: 'media',
		},
	});
}

//====================================================================


//预案列表
export async function getPlan(query: any) {
	return request({
		// url: server + '/api/v5/plan_model/getPlan',
		url: server + '/api.php/records/think_plan?database=media',
		method: 'get',
		params: query,
	});
}

// 添加预案
export async function addPlan(data: any) {
	return request({
		url: server + '/api/v5/plan_model/addPlan',
		method: 'post',
		data,
	});
}

// 编辑预案
export async function editPlan(data: any) {
	return request({
		url: server + '/api/v5/plan_model/editPlan',
		method: 'post',
		data,
	});
}

// 删除预案
export async function delPlan(data: any) {
	return request({
		url: server + '/api/v5/plan_model/delPlan',
		method: 'get',
		params: data,
	});
}

//启动预案
export async function startUpPlan(data: any) {
	return request({
		url: server + '/api/v5/plan_model/startUpPlan',
		method: 'post',
		data,
	});
}


//================================区域管理=======================================

//区域列表
export async function getRegion(query: any) {
	return request({
		// url: server + "/api/v5/region_model/getRegion",
		url: server + '/api.php/records/think_region?database=media',
		method: 'get',
		params: query,
	});
}

// 添加区域
export async function addRegion(data: any) {
	return request({
		// url: server + "/api/v5/region_model/addRegion",
		url: server + '/api.php/records/think_region',
		method: 'post',
		data,
		params: {
			database: 'media',
		},
		headers: {
			'Content-Type': 'application/json'
		},
	});
}

// 编辑区域
export async function editRegion(id: number, data: any) {
	return request({
		// url: server + "/api/v5/region_model/editRegion",
		url: server + '/api.php/records/think_region/' + id,
		method: on_off ? 'post' : "PUT",
		data,
		params: {
			database: 'media',
		},
		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

// 删除区域
export async function delRegion(id: any) {
	return request({
		// url: server + "/api/v5/region_model/delRegion",
		url: server + '/api.php/records/think_region/' + id,
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: {
			database: 'media',
		},
	});
}

//=======================分组管理============================
//分组列表
export async function getGrouping(query: any) {
	return request({
		// url: server + '/api/v5/grouping_model/getGrouping',
		url: server + '/api.php/records/think_grouping?database=media',
		method: 'get',
		params: query,
	});
}

// 添加分组
export async function addGrouping(data: any) {
	return request({
		url: server + '/api.php/records/think_grouping',
		method: 'post',
		data,
		params: {
			database: 'media',
		},
		headers: {
			'Content-Type': 'application/json'
		},
	});
}

// 编辑分组
export async function editGrouping(id: number, data: any) {
	return request({
		// url: server + '/api/v5/grouping_model/editGrouping',
		url: server + '/api.php/records/think_grouping/' + id,
		method: on_off ? 'post' : "PUT",
		data,
		params: {
			database: 'media',
		},
		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

// 删除分组
export async function delGrouping(id: any) {
	return request({
		// url: server + '/api/v5/grouping_model/groupingDelete',
		url: server + '/api.php/records/think_grouping/' + id,
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: {
			database: 'media',
		},
	});
}


//======================设备类型管理================================

//设备类型列表
export async function getDeviceType(query: any) {
	return request({
		url: server + '/api.php/records/think_device_type?database=media',
		method: 'get',
		params: query,
	});
}

// 添加设备类型
export async function addDeviceType(data: any) {
	return request({
		url: server + '/api.php/records/think_device_type',
		method: 'post',
		data,
		params: {
			database: 'media',
		},
		headers: {
			'Content-Type': 'application/json'
		},
	});
}

// 编辑设备类型
export async function editDeviceType(id: any, data: any) {
	return request({
		// url: server + "/api/v5/equipment_type_model/editDeviceType",
		url: server + '/api.php/records/think_device_type/' + id,
		method: on_off ? 'post' : "PUT",
		data,
		params: {
			database: 'media',
		},
		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

// 删除设备类型
export async function delDeviceType(id: any) {
	return request({
		// url: server + "/api/v5/equipment_type_model/delDeviceType",
		url: server + '/api.php/records/think_device_type/' + id,
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: {
			database: 'media',
		},
	});
}

//=============================================================

//坐席管理
export async function getSeats(query: any) {
	return request({
		// url: server + '/api/v5/seats_model/getSeats',
		url: server + '/api.php/records/think_seats?database=media',
		method: 'get',
		params: query,
	});
}

// 添加坐席
export async function addSeats(data: any) {
	return request({
		url: server + '/api.php/records/think_seats',
		method: 'post',
		data,
		params: {
			database: 'media',
		},
		headers: {
			'Content-Type': 'application/json'
		},
	});
}

// 编辑坐席
export async function editSeats(id: any, data: any) {
	return request({
		url: server + '/api.php/records/think_seats/' + id,
		method: on_off ? 'post' : "PUT",
		data,
		params: {
			database: 'media',
		},
		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

// 删除坐席
export async function delSeats(id: any) {
	return request({
		url: server + '/api.php/records/think_seats/' + id,
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: {
			database: 'media',
		},
	});
}

//===========================屏幕管理=======================================

//屏幕管理
export async function getOneScreen(id: any) {
	return request({
		url: server + '/api.php/records/think_screen/' + id,
		method: 'get',
		params: { database: 'media' },
	});
}
export async function getScreen(query: any) {
	return request({
		// url: server + "/api/v5/screen_model/getScreen",
		url: server + '/api.php/records/think_screen?database=media',
		method: 'get',
		params: query,
	});
}

// 添加屏幕
export async function addScreen(data: any) {
	return request({
		url: server + '/api.php/records/think_screen',
		method: 'post',
		data,
		params: {
			database: 'media',
		},
		headers: {
			'Content-Type': 'application/json'
		},
	});
}

// 编辑屏幕
export async function editScreen(id: number, data: any) {
	return request({
		url: server + '/api.php/records/think_screen/' + id,
		method: on_off ? 'post' : "PUT",
		data,
		params: {
			database: 'media',
		},
		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

// 删除屏幕
export async function delScreen(id: any) {
	return request({
		// url: server + "/api/v5/screen_model/delScreen",
		url: server + '/api.php/records/think_screen/' + id,
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: {
			database: 'media',
		},
	});
}


//=============================人流管理=====================================
export async function getAbortion(query: any) {
	return request({
		// url: server + "/api/v5/equipment_model/getDevices",
		url: server + '/api.php/records/think_people_counting?database=media&join=think_default_play',
		method: 'get',
		params: query,
	});
}

export async function addAbortion(data: any) {
	return request({
		// url: server + "/api/v5/equipment_model/addDevice",
		url: server + '/api.php/records/think_people_counting',
		method: 'post',
		data,
		params: {
			database: 'media',
		},
		headers: {
			'Content-Type': 'application/json'
		},
	});
}

export async function editAbortion(id: any, data: any) {
	return request({
		url: server + '/api.php/records/think_people_counting/' + id,
		method: on_off ? 'post' : "PUT",
		data,
		params: {
			database: 'media',
		},
		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

export async function delAbortion(id: any) {
	return request({
		// url: server + "/api/v5/equipment_model/delDevice",
		url: server + '/api.php/records/think_people_counting/' + id,
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: {
			database: 'media',
		},
	});
}



//=============================人流统计=====================================
export async function getPeopleCount(query: any) {
	return request({
		// url: server + "/api/v5/equipment_model/getDevices",
		url: server + '/api.php/records/think_people_count?database=media&join=think_default_play',
		method: 'get',
		params: query,
	});
}

export async function addPeopleCount(data: any) {
	return request({
		// url: server + "/api/v5/equipment_model/addDevice",
		url: server + '/api.php/records/think_people_count',
		method: 'post',
		data,
		params: {
			database: 'media',
		},
		headers: {
			'Content-Type': 'application/json'
		},
	});
}

export async function editPeopleCount(id: any, data: any) {
	return request({
		url: server + '/api.php/records/think_people_count/' + id,
		method: on_off ? 'post' : "PUT",
		data,
		params: {
			database: 'media',
		},
		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

export async function delPeopleCount(id: any) {
	return request({
		// url: server + "/api/v5/equipment_model/delDevice",
		url: server + '/api.php/records/think_people_count/' + id,
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: {
			database: 'media',
		},
	});
}


//=============================设备管理=====================================

// 获取设备列表
export async function getDevices(query: any) {
	return request({
		// url: server + "/api/v5/equipment_model/getDevices",
		url: server + '/api.php/records/think_device?database=media&join=think_default_play',
		method: 'get',
		params: query,
	});
}

// 添加设备
export async function addDevice(data: any) {
	return request({
		// url: server + "/api/v5/equipment_model/addDevice",
		url: server + '/api.php/records/think_device',
		method: 'post',
		data,
		params: {
			database: 'media',
		},
		headers: {
			'Content-Type': 'application/json'
		},
	});
}

// 编辑设备
export async function editDevice(id: any, data: any) {
	return request({
		url: server + '/api.php/records/think_device/' + id,
		method: on_off ? 'post' : "PUT",
		data,
		params: {
			database: 'media',
		},
		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

// 删除设备
export async function delDevice(id: any) {
	return request({
		// url: server + "/api/v5/equipment_model/delDevice",
		url: server + '/api.php/records/think_device/' + id,
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: {
			database: 'media',
		},
	});
}

//======================设备配置================================
// 获取设备配置
export async function getFindDeviceConfig(data: any) {
	return request({
		url: server + '/api.php/records/think_device_config?database=media',
		method: 'get',
		params: data,
	});
}

//修改-设备配置
export async function editFindDeviceConfig(id: any, data: any) {
	return request({
		// url: server + "/api.php/records/think_device_config?database=media",
		url: server + '/api.php/records/think_device_config/' + id,
		method: on_off ? 'post' : "PUT",
		data,
		params: {
			database: 'media',
		},
		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

// ==========================设备组管理========================================

// 获取设备组列表
export async function getDeviceGroup(query: any) {
	return request({
		// url: server + "/api/v5/equipment_group_model/getDeviceGroup",
		url: server + '/api.php/records/think_device_group?database=media',
		method: 'get',
		params: query,
	});
}

// 添加设备组
export async function addDeviceGroup(data: any) {
	return request({
		// url: server + "/api/v5/equipment_group_model/addDeviceGroup",
		url: server + '/api.php/records/think_device_group',
		method: 'post',
		data,
		params: {
			database: 'media',
		},
		headers: {
			'Content-Type': 'application/json'
		},
	});
}

// 编辑设备组
export async function editDeviceGroup(id: any, data?: any) {
	return request({
		// url: server + "/api/v5/equipment_group_model/editDeviceGroup",
		url: server + '/api.php/records/think_device_group/' + id,
		method: on_off ? 'post' : "PUT",
		data,
		params: {
			database: 'media',
		},
		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

// 删除设备组
export async function delDeviceGroup(id: any) {
	return request({
		// url: server + "/api/v5/equipment_group_model/delDeviceGroup",
		url: server + '/api.php/records/think_device_group/' + id,
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: {
			database: 'media',
		},
	});
}

// ===========================节目管理=================================
// 获取节目列表
export async function getLayouts(query: any) {
	return request({
		// url: server + "/api/v5/program_model/getLayouts",
		url: server + '/api.php/records/think_layout?database=media',
		method: 'get',
		params: query,
	});
}

// 添加节目
export async function addLayout(data: any) {
	return request({
		// url: server + "/api/v5/program_model/addLayout",
		url: server + '/api.php/records/think_layout',
		method: 'post',
		data,
		params: {
			database: 'media',
		},
		headers: {
			'Content-Type': 'application/json'
		},
	});
}

// 编辑节目
export async function editLayout(id: any, data: any) {
	return request({
		// url: server + "/api/v5/program_model/editLayout",
		url: server + '/api.php/records/think_layout/' + id,
		method: on_off ? 'post' : "PUT",
		data,
		params: {
			database: 'media',
		},
		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

// 删除节目
export async function delLayout(id: any) {
	return request({
		// url: server + "/api/v5/program_model/delLayout",
		url: server + '/api.php/records/think_layout/' + id,
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: {
			database: 'media',
		},
	});
}



// ========================节目模板管理====================================
// 获取节目模板列表
export async function getTemplate(query: any) {
	return request({
		// url: server + "/api/v5/template_model/getTemplate",
		url: server + '/api.php/records/think_template?database=media',
		method: 'get',
		params: query,
	});
}

// 添加节目模板
export async function addTemplate(data: any) {
	return request({
		// url: server + "/api/v5/template_model/addTemplate",
		url: server + '/api.php/records/think_template',
		method: 'post',
		data,
		params: {
			database: 'media',
		},
		headers: {
			'Content-Type': 'application/json'
		},
	});
}

// 编辑节目模板
export async function editTemplate(id: any, data: any) {
	return request({
		// url: server + "/api/v5/template_model/editTemplate",
		url: server + '/api.php/records/think_template/' + id,
		method: on_off ? 'post' : "PUT",
		data,
		params: {
			database: 'media',
		},
		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

// 删除节目模板
export async function delTemplate(id: any) {
	return request({
		// url: server + "/api/v5/template_model/delTemplate",
		url: server + '/api.php/records/think_template/' + id,
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: {
			database: 'media',
		},
	});
}


// ===========================节目组管理=======================================
// 获取节目组列表
export async function getLayoutGroup(query: any) {
	return request({
		// url: server + "/api/v5/program_group_model/getLayoutGroup",
		url: server + '/api.php/records/think_layout_group?database=media',
		method: 'get',
		params: query,
	});
}

// 添加节目组
export async function addLayoutGroup(data: any) {
	return request({
		// url: server + "/api/v5/program_group_model/addLayoutGroup",
		url: server + '/api.php/records/think_layout_group',
		method: "post",
		data,
		params: {
			database: 'media',
		},
		headers: {
			'Content-Type': 'application/json'
		},
	});
}

// 编辑节目组
export async function editLayoutGroup(id: any, data: any) {
	return request({
		// url: server + "/api/v5/program_group_model/editLayoutGroup",
		url: server + '/api.php/records/think_layout_group/' + id,
		method: on_off ? 'post' : "PUT",
		data,
		params: {
			database: 'media',
		},
		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

// 删除节目组
export async function delLayoutGroup(id: any) {
	return request({
		// url: server + "/api/v5/program_group_model/delLayoutGroup",
		url: server + '/api.php/records/think_layout_group/' + id,
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: {
			database: 'media',
		},
	});
}

// ========================素材管理==========================================

// 获取素材列表
export async function getMedia(query: any) {
	return request({
		// url: server + "/api/v5/media_model/getMedia",
		url: server + '/api.php/records/think_media?database=media',
		method: 'get',
		params: query,
	});
}

// 获取素材列表
export async function getMediaKey(query: any) {
	return requestKey({
		// url: server + "/api/v5/media_model/getMedia",
		url: server + '/apikey.php/records/think_media?database=media',
		method: 'get',
		params: query,
	});
}

// 添加素材
export async function addMedia(data: any) {
	return request({
		// url: server + '/api/v5/media_model/addMedia',
		url: server + '/api.php/records/think_media',
		method: 'post',
		data,
		params: {
			database: 'media',
		},
		headers: {
			'Content-Type': 'application/json'
		},
	});
}

// 编辑素材
export async function editMedia(id: any, data: any) {
	return request({
		// url: server + '/api/v5/media_model/editMedia',
		url: server + '/api.php/records/think_media/' + id,
		method: on_off ? 'post' : "PUT",
		data,
		params: {
			database: 'media',
		},
		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

// 删除素材
export async function delMedia(data: any) {
	return request({
		url: server + '/api.php/records/think_media/' + data,
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: {
			database: 'media',
		},
	});
}

// =========================排程管理=========================================
// 获取排程列表
export async function getSchedule(query: any) {
	return request({
		// url: server + "/api/v5/schedule_model/getSchedule",
		url: server + '/api.php/records/think_schedule?database=media',
		method: 'get',
		params: query,
	});
}

// 添加排程
export async function addSchedule(data: any) {
	return request({
		// url: server + "/api/v5/schedule_model/addSchedule",
		url: server + '/api.php/records/think_schedule',
		method: 'post',
		data,
		params: {
			database: 'media',
		},
		headers: {
			'Content-Type': 'application/json'
		},
	});
}

// 编辑排程
export async function editSchedule(id: any, data: any) {
	return request({
		// url: server + "/api/v5/schedule_model/editSchedule",
		url: server + '/api.php/records/think_schedule/' + id,
		method: on_off ? 'post' : "PUT",
		data,
		params: {
			database: 'media',
		},
		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

// 删除排程
export async function delSchedule(id: any) {
	return request({
		// url: server + "/api/v5/schedule_model/delSchedule",
		url: server + '/api.php/records/think_schedule/' + id,
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: {
			database: 'media',
		},
	});
}

// ============================插播管理======================================
// 获取插播列表
export async function getInsertion(query: any) {
	return request({
		// url: server + "/api/v5/insertion_model/getInsertion",
		url: server + '/api.php/records/think_urgent_insert?database=media',
		method: 'get',
		params: query,
	});
}

// 添加插播
export async function addInsertion(data: any) {
	return request({
		// url: server + "/api/v5/insertion_model/addInsertion",
		url: server + '/api.php/records/think_urgent_insert',
		method: 'post',
		data,
		params: {
			database: 'media',
		},
		headers: {
			'Content-Type': 'application/json'
		},
	});
}

// 编辑插播
export async function editInsertion(id: any, data: any) {
	return request({
		// url: server + "/api/v5/insertion_model/editInsertion",
		url: server + '/api.php/records/think_urgent_insert/' + id,
		method: on_off ? 'post' : "PUT",
		data,
		params: {
			database: 'media',
		},
		headers: on_off ? {
			'Content-Type': 'application/json',
			"X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

// 删除插播
export async function delInsertion(id: any) {
	return request({
		// url: server + "/api/v5/insertion_model/delInsertion",
		url: server + '/api.php/records/think_urgent_insert/' + id,
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: {
			database: 'media',
		},
	});
}


// =================================监控管理======================================
// 获取监控列表
export async function getMonitor(query: any) {
	return request({
		// url: server + '/api/v5/monitor_model/getMonitor',
		url: server + '/api.php/records/think_monitor?database=media',
		method: 'get',
		params: query,
	});
}

// 添加监控
export async function addMonitor(data: any) {
	return request({
		// url: server + '/api/v5/monitor_model/addMonitor',
		url: server + '/api.php/records/think_monitor',
		method: 'post',
		data,
		params: {
			database: 'media',
		},
	});
}

// 编辑监控
export async function editMonitor(id: number, data: any) {
	return request({
		url: server + '/api.php/records/think_monitor/' + id,
		method: on_off ? 'post' : "PUT",
		data,
		params: {
			database: 'media',
		},
		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

// 删除监控
export async function delMonitor(id: any) {
	return request({
		url: server + '/api.php/records/think_monitor/' + id,
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: {
			database: 'media',
		},
	});
}

//导入数据
export async function ImportMonitor(data: any) {
	return request({
		url: server + '/api/v5/monitor_model/importMonitor',
		method: 'post',
		data,
	});
}

//监测数据导出
export async function exportMonitor(query: any) {
	return axios({
		url: server + `/api/v5/monitor_model/exportMonitor`,
		method: 'GET',
		responseType: 'blob', //设置响应格式
		params: query,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'Sign-Code': getStorage('SignCode'),
		},
	});
}

//启动WebRtc
export async function startUpWebRtc(data: any) {
	return request({
		url: server + '/api/v5/monitor_model/startUpWebRtc',
		method: 'post',
		data,
	});
}

//监控webRtc
export async function monitorPtzControl(query: any) {
	return axios({
		url: Config.PTZControl + `api/v1/gb28181`,
		method: 'GET',
		params: query,
		headers: {
			'Content-Type': 'text/html',
		},
	});
}


//===============================输入流管理=======================================

// 获取输入流列表
export async function getInputStream(query: any) {
	return request({
		// url: server + '/api/v5/monitor_model/getMonitor',
		url: server + '/api.php/records/think_input_stream?database=media',
		method: 'get',
		params: query,
	});
}

// 添加输入流列表
export async function addInputStream(data: any) {
	return request({
		// url: server + '/api/v5/monitor_model/addMonitor',
		url: server + '/api.php/records/think_input_stream',
		method: 'post',
		data,
		params: {
			database: 'media',
		},
		headers: {
			'Content-Type': 'application/json'
		},
	});
}

// 编辑输入流列表
export async function editInputStream(id: number, data: any) {
	return request({
		url: server + '/api.php/records/think_input_stream/' + id,
		method: on_off ? 'post' : "PUT",
		data,
		params: {
			database: 'media',
		},
		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

// 删除输入流列表
export async function delInputStream(id: any) {
	return request({
		url: server + '/api.php/records/think_input_stream/' + id,
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: {
			database: 'media',
		},
	});
}




//=========================安装包========================================
// 获取安装包
export async function getFixedTime(query: any) {
	return request({
		url: server + '/api.php/records/think_fixed_time?database=media',
		method: 'get',
		params: query,
	});
}

// 添加安装包
export async function addFixedTime(data: any) {
	return request({
		url: server + '/api.php/records/think_fixed_time',
		method: 'post',
		data,
		params: {
			database: 'media',
		},
		headers: {
			'Content-Type': 'application/json'
		},
	});
}

// 编辑安装包
export async function editFixedTime(id: number, data: any) {
	return request({
		url: server + '/api.php/records/think_fixed_time/' + id,
		method: on_off ? 'post' : "PUT",
		data,
		params: {
			database: 'media',
		},
		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}


// 删除安装包
export async function delFixedTime(id: any) {
	return request({
		url: server + '/api.php/records/think_fixed_time/' + id,
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: {
			database: 'media',
		},
	});
}


// ==========================设备组管理========================================

// 获取-商品列表
export async function getProduct(query: any) {
	return request({
		// url: server + "/api/v5/equipment_group_model/getDeviceGroup",
		url: server + '/api.php/records/think_commodity?database=media',
		method: 'get',
		params: query,
	});
}

// 添加-商品列表
export async function addProduct(data: any) {
	return request({
		// url: server + "/api/v5/equipment_group_model/addDeviceGroup",
		url: server + '/api.php/records/think_commodity',
		method: 'post',
		data,
		params: {
			database: 'media',
		},
		headers: {
			'Content-Type': 'application/json'
		},
	});
}

// 编辑-商品列表
export async function editProduct(id: any, data?: any) {
	return request({
		// url: server + "/api/v5/equipment_group_model/editDeviceGroup",
		url: server + '/api.php/records/think_commodity/' + id,
		method: on_off ? 'post' : "PUT",
		data,
		params: {
			database: 'media',
		},
		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

// 删除-商品列表
export async function delProduct(id: any) {
	return request({
		// url: server + "/api/v5/equipment_group_model/delDeviceGroup",
		url: server + '/api.php/records/think_commodity/' + id,
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: {
			database: 'media',
		},
	});
}


//=================定时开关机==================================

// 获取安装包
export async function getApkRenew(query: any) {
	return request({
		url: server + '/api.php/records/think_apk_renew?database=media&join=think_device_type&join=think_media',
		method: 'get',
		params: query,
	});
}

export async function getApkRenewKey(query: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_apk_renew?database=media&join=think_device_type&join=think_media',
		method: 'get',
		params: query,
	});
}

export async function getApkRenewKey2(query: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_apk_renew?database=meeting_dingding&join=think_device_types&join=think_media',
		method: 'get',
		params: query,
	});
}
export async function getApkRenewKey3(query: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_apk_renew?database=central_control&join=think_device_types&join=think_media',
		method: 'get',
		params: query,
	});
}



// 添加安装包
export async function addApkRenew(data: any) {
	return request({
		url: server + '/api.php/records/think_apk_renew',
		method: 'post',
		data,
		params: {
			database: 'media',
		},
		headers: {
			'Content-Type': 'application/json'
		},
	});
}

// 编辑安装包
export async function editApkRenew(id: number, data: any) {
	return request({
		url: server + '/api.php/records/think_apk_renew/' + id,
		method: on_off ? 'post' : "PUT",
		data,
		params: {
			database: 'media',
		},
		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}


// 删除安装包
export async function delApkRenew(id: any) {
	return request({
		url: server + '/api.php/records/think_apk_renew/' + id,
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: {
			database: 'media',
		},
	});
}


//=============================设备下载日志管理=====================================
export async function getDownloadLog(query: any) {
	return request({
		// url: server + "/api/v5/equipment_model/getDevices",
		url: server + '/api.php/records/think_download_log?database=media&join=think_device&join=think_layout',
		method: 'get',
		params: query,
	});
}





// ==========================节目导出===========================
export async function exportProgram(data: any) {
	const formData = new FormData();
	formData.append('id', data.id);
	formData.append('org_num', data.org_num);

	return request({
		url: server + '/api.php/exportProgram',
		method: 'post',
		data: formData,
		params: {
			database: 'media',
		},
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
}

export async function exportTemplate(data: any) {
	const formData = new FormData();
	formData.append('id', data.id);
	formData.append('org_num', data.org_num);

	return request({
		url: server + '/api.php/exportTemplate',
		method: 'post',
		data: formData,
		params: {
			database: 'media',
		},
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
}

// ===================================
