import React from 'react';
import {
	componentsMenu,
	dashboardMenu,
	demoPages,
	layoutMenu,
	mediaPages,
	meetingRoomPages,
	pipeGalleryPages,
	energyConsumptionPages,
	centralControlPages,
	systemLayoutPages,
} from '../menu';
import DashboardHeader from '../pages/_layout/_headers/DashboardHeader';
import DashboardBookingHeader from '../pages/_layout/_headers/DashboardBookingHeader';
import ProfilePageHeader from '../pages/_layout/_headers/ProfilePageHeader';
import SummaryHeader from '../pages/_layout/_headers/SummaryHeader';
import ProductsHeader from '../pages/_layout/_headers/ProductsHeader';
import ProductListHeader from '../pages/_layout/_headers/ProductListHeader';
import PageLayoutHeader from '../pages/_layout/_headers/PageLayoutHeader';
import ComponentsHeader from '../pages/_layout/_headers/ComponentsHeader';
import FormHeader from '../pages/_layout/_headers/FormHeader';
import ChartsHeader from '../pages/_layout/_headers/ChartsHeader';
import ContentHeader from '../pages/_layout/_headers/ContentHeader';
import UtilitiesHeader from '../pages/_layout/_headers/UtilitiesHeader';
import IconHeader from '../pages/_layout/_headers/IconHeader';
import DefaultHeader from '../pages/_layout/_headers/DefaultHeader';

import OtherHeader from '../pages/_layout/_headers/OtherHeader';

import BookingManagementHeader from '../pages/_layout/_headers/meeting/BookingManagementHeader';
import MeetingRoomManagementHeader from '../pages/_layout/_headers/meeting/MeetingRoomManagementHeader';
import SystemManagementHeader from '../pages/_layout/_headers/meeting/SystemManagementHeader';
import StatementManagementHeader from '../pages/_layout/_headers/meeting/StatementManagementHeader';
import LogManagementHeader from '../pages/_layout/_headers/meeting/LogManagementHeader';
import DatabaseManagementHeader from '../pages/_layout/_headers/meeting/DatabaseManagementHeader';

import ProgramManagementHeader_media from '../pages/_layout/_headers/media/ProgramManagementHeader';

import EquipmentManagementHeader_pipeGallery from '../pages/_layout/_headers/pipeGallery/EquipmentManagementHeader';
import SupplierManagementHeader_pipeGallery from '../pages/_layout/_headers/pipeGallery/SupplierManagementHeader';
import MonitoringManagementHeader_pipeGallery from '../pages/_layout/_headers/pipeGallery/MonitoringManagementHeader';
import MaintenanceManagementHeader_pipeGallery from '../pages/_layout/_headers/pipeGallery/MaintenanceManagementHeader';
import KnowledgeManagementHeader_pipeGallery from '../pages/_layout/_headers/pipeGallery/KnowledgeManagementHeader';
import GisMapHeader_pipeGallery from '../pages/_layout/_headers/pipeGallery/GisMapHeader';
import SystemManagementHeader_pipeGallery from '../pages/_layout/_headers/pipeGallery/SystemManagementHeader';

import SystemManagementHeader_centralControl from '../pages/_layout/_headers/centralControl/SystemManagementHeader';

// 能耗
import Dashboard_energyConsumption from '../pages/_layout/_headers/energyConsumption/DashboardHeader';
import EquipmentManagementHeader_energyConsumption from '../pages/_layout/_headers/energyConsumption/EquipmentManagementHeader';

const headers = [
	{ path: layoutMenu.pageLayout.subMenu.onlySubheader.path, element: null, exact: true },
	{ path: layoutMenu.pageLayout.subMenu.onlyContent.path, element: null, exact: true },
	{ path: layoutMenu.blank.path, element: null, exact: true },
	{ path: layoutMenu.excelMap.path, element: null, exact: true },
	{ path: demoPages.generateKeyPage.path, element: null, exact: true },
	{ path: demoPages.login.path, element: null, exact: true },
	{ path: demoPages.signUp.path, element: null, exact: true },
	{ path: demoPages.page404.path, element: null, exact: true },
	{ path: demoPages.knowledge.subMenu.grid.path, element: null, exact: true },
	// { path: mediaPages.systemManagement.subMenu.operateRoleManagement.path + "/:powerId", element: null, exact: true },
	// {
	// 	path: paperlessPages.meetingList.subMenu.notCompletedMeeting.path,
	// 	element: null,
	// 	exact: true,
	// },
	// {
	// 	path: paperlessPages.meetingList.subMenu.appointmentMeeting.path,
	// 	element: null,
	// 	exact: true,
	// },
	// { path: paperlessPages.meetingList.subMenu.archivedMeeting.path, element: null, exact: true },
	// { path: paperlessPages.meetingList.subMenu.templateMeeting.path, element: null, exact: true },
	{ path: mediaPages.mobilePhoneScanModel.subMenu.comprehensiveList.path, element: null, exact: true },
	{ path: 'paperless/meetingList/_details/meetingDetails', element: null, exact: true},
	{ path: 'paperless/meetingList/_details/meetingDetail/:id', element: null, exact: true },
	{ path: 'paperless/meetingList/_details/participants/:id', element: null, exact: true },
	{ path: 'paperless/meetingList/_details/addParticipants/:id', element: null, exact: true },
	{ path: 'paperless/meetingList/_details/annotationRecord/:id', element: null, exact: true },
	{ path: 'paperless/meetingList/_details/fileManagement/:id', element: null, exact: true },
	{ path: 'paperless/meetingList/_details/videoManagement/:id', element: null, exact: true },
	{ path: 'paperless/meetingList/_details/votingManagement/:id', element: null, exact: true },
	{ path: 'paperless/meetingList/_details/votingDetailedManagement/:id', element: null, exact: true },
	{path: 'paperless/meetingList/_details/votingGroupManagement/:id', element: null, exact: true},
	{path: 'paperless/meetingList/_details/informationManagement/:id', element: null, exact: true},
	{ path: 'paperless/meetingList/_details/conferenceRecords/:id', element: null, exact: true },
	{ path: 'paperless/meetingList/_details/seatMapManagement/:id', element: null, exact: true },
	{ path: 'paperless/meetingList/_details/seatMapArrangement/:id', element: null, exact: true },
	{ path: 'paperless/meetingList/_details/voiceSummary/:id', element: null, exact: true },
	{ path: 'paperless/systemSettings/meetingRoomImg', element: null, exact: true},
	{ path: `${mediaPages.programManagement.subMenu.programAdd.path}/:templateId`, element: null, exact: true },
	{ path: `${mediaPages.programManagement.subMenu.programEdit.path}/:templateId`, element: null, exact: true },
	{ path: 'check-in', element: null, exact: true },
	{ path: 'callback', element: null, exact: true },
	// { path: 'paperless/systemSettings/addPermission', element: null, exact: true},
	{
		path: `${demoPages.editPassword.path}/:username`, element: null, exact: true
	},
	{
		path: `${demoPages.empowerPage.path}`, element: null, exact: true
	},
	{ path: 'PdfPreview/:fileMd5', element: null, exact: true },
	{ path: 'paperless/meetingList/votingStatistics/:id', element: null, exact: true },
	{ path: 'paperless/meetingList/_details/votingGroupDetailedManagement/:id', element: null, exact: true },
	// { path: paperlessPages.personalAdministration.subMenu.administratorList.path, element: null, exact: true },
	// { path: dashboardMenu.dashboard.path, element: <Dashboard_energyConsumption title='办公空间可视化' />, exact: true },
	{
		path: demoPages.projectManagement.subMenu.list.path,
		element: <DashboardHeader />,
		exact: true,
	},
	{
		path: `${mediaPages.systemManagement.subMenu.addOrganization.path}/:orgNumId`,
		element: null,
		exact: true,
	},
	{
		path: `${mediaPages.systemManagement.subMenu.addOrganizationDetails.path}/:orgNumId`,
		element: null,
		exact: true,
	},
	{ path: demoPages.pricingTable.path, element: <DashboardHeader />, exact: true },
	// {
	// 	path: dashboardMenu.dashboard.path,
	// 	element: <DashboardBookingHeader />,
	// 	exact: true,
	// },
	// {
	// 	path: dashboardMenu.eventCenter.path,
	// 	element: <Dashboard_energyConsumption title='事件中心' />,
	// 	exact: true,
	// },
	// {
	// 	path: dashboardMenu.noticeCenter.path,
	// 	element: <Dashboard_energyConsumption title='通知中心' />,
	// 	exact: true,
	// },
	{
		path: demoPages.appointment.subMenu.calendar.path,
		element: <DashboardBookingHeader />,
		exact: true,
	},
	// {
	// 	path: demoPages.appointment.subMenu.employeeList.path,
	// 	element: <DashboardBookingHeader />,
	// 	exact: true,
	// },
	{
		path: demoPages.listPages.subMenu.listFluid.path,
		element: <DashboardBookingHeader />,
		exact: true,
	},
	{
		path: `${demoPages.editPages.path}/*`,
		element: <DashboardBookingHeader />,
		exact: true,
	},
	// {
	// 	path: `${demoPages.appointment.subMenu.employeeID.path}/*`,
	// 	element: <DashboardBookingHeader />,
	// 	exact: true,
	// },
	{
		path: `${demoPages.projectManagement.subMenu.itemID.path}/*`,
		element: <DashboardBookingHeader />,
		exact: true,
	},
	{
		path: demoPages.singlePages.subMenu.fluidSingle.path,
		element: <ProfilePageHeader />,
		exact: true,
	},
	{
		path: demoPages.singlePages.subMenu.boxedSingle.path,
		element: <ProfilePageHeader />,
		exact: true,
	},
	{
		path: demoPages.sales.subMenu.transactions.path,
		element: <ProfilePageHeader />,
		exact: true,
	},
	{
		path: demoPages.chat.subMenu.withListChat.path,
		element: <ProfilePageHeader />,
		exact: true,
	},
	{
		path: demoPages.chat.subMenu.onlyListChat.path,
		element: <ProfilePageHeader />,
		exact: true,
	},
	{
		path: `${demoPages.knowledge.subMenu.itemID.path}/:id`,
		element: <ProfilePageHeader />,
		exact: true,
	},
	{
		path: demoPages.crm.subMenu.dashboard.path,
		element: <ProfilePageHeader />,
		exact: true,
	},
	{
		path: demoPages.crm.subMenu.customersList.path,
		element: <ProfilePageHeader />,
		exact: true,
	},
	{
		path: `${demoPages.crm.subMenu.customerID.path}/:id`,
		element: <ProfilePageHeader />,
		exact: true,
	},
	{
		path: dashboardMenu.summary.path,
		element: <SummaryHeader />,
		exact: true,
	},
	{
		path: demoPages.gridPages.subMenu.gridBoxed.path,
		element: <ProductsHeader />,
		exact: true,
	},
	{
		path: demoPages.gridPages.subMenu.gridFluid.path,
		element: <ProductsHeader />,
		exact: true,
	},
	{
		path: demoPages.listPages.subMenu.listBoxed.path,
		element: <ProductListHeader />,
		exact: true,
	},
	{
		path: demoPages.sales.subMenu.salesList.path,
		element: <ProductListHeader />,
		exact: true,
	},
	{
		path: demoPages.sales.subMenu.productsGrid.path,
		element: <ProductListHeader />,
		exact: true,
	},
	{
		path: `${demoPages.sales.subMenu.productID.path}/:id`,
		element: <ProductListHeader />,
		exact: true,
	},
	{
		path: `${layoutMenu.asideTypes.path}/*`,
		element: <PageLayoutHeader />,
		exact: true,
	},
	{
		path: layoutMenu.pageLayout.subMenu.headerAndSubheader.path,
		element: <PageLayoutHeader />,
		exact: true,
	},
	{
		path: layoutMenu.pageLayout.subMenu.onlyHeader.path,
		element: <PageLayoutHeader />,
		exact: true,
	},
	{
		path: `${componentsMenu.components.path}/*`,
		element: <ComponentsHeader />,
	},
	{
		path: `${componentsMenu.forms.path}/*`,
		element: <FormHeader />,
	},
	{
		path: `${componentsMenu.charts.path}/*`,
		element: <ChartsHeader />,
	},
	{
		path: `${componentsMenu.content.path}/*`,
		element: <ContentHeader />,
	},
	{
		path: `${componentsMenu.utilities.path}/*`,
		element: <UtilitiesHeader />,
	},
	{
		path: `${componentsMenu.icons.path}/*`,
		element: <IconHeader />,
	},
];

// 信发
const media = [
	{
		path: mediaPages.dashboard.path,
		element: <Dashboard_energyConsumption title='仪表盘-信发' />,
		exact: true,
	},

	{
		path: `${mediaPages.taskManagement.path}/*`,
		element: <OtherHeader />,
	},

	// 播放管理
	{
		path: `${mediaPages.playManagement.path}/*`,
		element: (
			<ProgramManagementHeader_media
				subMenu={mediaPages.playManagement.subMenu}
				id={mediaPages.playManagement.id}
			/>
		),
	},

	{
		path: `${mediaPages.programManagement.path}/*`,
		element: (
			<ProgramManagementHeader_media
				subMenu={mediaPages.programManagement.subMenu}
				id={mediaPages.programManagement.id}
			/>
		),
	},
	{
		path: `${mediaPages.equipmentManagement.path}/*`,
		element: (
			<ProgramManagementHeader_media
				subMenu={mediaPages.equipmentManagement.subMenu}
				id={mediaPages.equipmentManagement.id}
			/>
		),
	},
	{
		path: `${mediaPages.systemManagement.path}/*`,
		element: (
			<ProgramManagementHeader_media
				subMenu={mediaPages.systemManagement.subMenu}
				id={mediaPages.systemManagement.id}
			/>
		),
	},

	{ path: mediaPages.about.path, element: <OtherHeader />, exact: true },
];

// 会议
const meeting = [
	{
		path: meetingRoomPages.dashboard.path,
		element: <Dashboard_energyConsumption title='仪表盘-会务' />,
		exact: true,
	},

	{
		path: `${meetingRoomPages.bookingManagement.path}/*`,
		element: <BookingManagementHeader />,
	},
	{
		path: `${meetingRoomPages.meetingRoomManagement.path}/*`,
		element: <MeetingRoomManagementHeader />,
	},
	{
		path: `${meetingRoomPages.meetingStatementManagement.path}`,
		element: <Dashboard_energyConsumption title='会议纪要管理' />,
	},
	{
		path: `${meetingRoomPages.meetingSignManagement.path}`,
		element: <Dashboard_energyConsumption title='会议签到管理' />,
	},
	{
		path: `${meetingRoomPages.equipmentManagement.path}/*`,
		element: (
			<ProgramManagementHeader_media
				subMenu={meetingRoomPages.equipmentManagement.subMenu}
				id={meetingRoomPages.equipmentManagement.id}
			/>
		),
	},
	{
		path: `${meetingRoomPages.systemManagement.path}/*`,
		element: <SystemManagementHeader />,
	},
	{
		path: `${meetingRoomPages.statementManagement.path}/*`,
		element: <StatementManagementHeader />,
	},
	{
		path: `${meetingRoomPages.logManagement.path}/*`,
		element: <LogManagementHeader />,
	},
	{
		path: `${meetingRoomPages.databaseManagement.path}/*`,
		element: <DatabaseManagementHeader />,
	},
	{ path: meetingRoomPages.about.path, element: <OtherHeader />, exact: true },
];

// 管廊
const pipeGallery = [
	{
		path: pipeGalleryPages.dashboard.path,
		element: <OtherHeader />,
		exact: true,
	},
	// {
	// 	path: pipeGalleryPages.largeVisualScreen.path,
	// 	element: <OtherHeader />,
	// 	exact: true
	// },

	{
		path: `${pipeGalleryPages.equipmentManagement.path}/*`,
		element: <EquipmentManagementHeader_pipeGallery />,
	},

	{
		path: `${pipeGalleryPages.accountManagement.path}/*`,
		element: <SupplierManagementHeader_pipeGallery />,
	},

	{
		path: `${pipeGalleryPages.monitoringManagement.path}/*`,
		element: <MonitoringManagementHeader_pipeGallery />,
	},

	{
		path: `${pipeGalleryPages.maintenanceManagement.path}/*`,
		element: <MaintenanceManagementHeader_pipeGallery />,
	},

	{
		path: `${pipeGalleryPages.knowledgeManagement.path}/*`,
		element: <KnowledgeManagementHeader_pipeGallery />,
	},

	{
		path: `${pipeGalleryPages.gisMap.path}/*`,
		element: <GisMapHeader_pipeGallery />,
	},

	{
		path: `${pipeGalleryPages.systemManagement.path}/*`,
		element: <SystemManagementHeader_pipeGallery />,
	},

	// 可视化大屏
	{ path: pipeGalleryPages.largeVisualScreen.path, element: null, exact: true },

	// gis
	{ path: pipeGalleryPages.gisMap.subMenu.mapDevice.path, element: null, exact: true },

	// 数字孪生
	{ path: pipeGalleryPages.twinDigital.subMenu.pipeGallery3d.path, element: null, exact: true },
];

// 能耗
const energyConsumption = [
	{
		path: energyConsumptionPages.dashboard.path,
		element: <Dashboard_energyConsumption title='智能能耗管理' />,
		exact: true,
	},

	{
		path: `${energyConsumptionPages.equipmentManagement.path}/*`,
		element: (
			<ProgramManagementHeader_media
				subMenu={energyConsumptionPages.equipmentManagement.subMenu}
				id={energyConsumptionPages.equipmentManagement.id}
			/>
		),
	},
	{
		path: `${energyConsumptionPages.controlManagement.path}/*`,
		element: (
			<ProgramManagementHeader_media
				subMenu={energyConsumptionPages.controlManagement.subMenu}
				id={energyConsumptionPages.controlManagement.id}
			/>
		),
	},
];

// 中控|数字孪生
const centralControl = [
	{
		path: centralControlPages.dashboard.path,
		element: <Dashboard_energyConsumption title='仪表盘-物联中控' />,
		exact: true,
	},

	{
		path: `${centralControlPages.controlManagement.path}/*`,
		element: (
			<ProgramManagementHeader_media
				subMenu={centralControlPages.controlManagement.subMenu}
				id={centralControlPages.controlManagement.id}
			/>
		),
	},

	{
		path: `${centralControlPages.equipmentManagement.path}/*`,
		element: (
			<ProgramManagementHeader_media
				subMenu={centralControlPages.equipmentManagement.subMenu}
				id={centralControlPages.equipmentManagement.id}
			/>
		),
	},

	{
		path: `${centralControlPages.reportForm.path}/*`,
		element: (
			<ProgramManagementHeader_media
				subMenu={centralControlPages.reportForm.subMenu}
				id={centralControlPages.reportForm.id}
			/>
		),
	},

	{
		path: `${centralControlPages.controlPanel.path}/*`,
		element: (
			<ProgramManagementHeader_media
				subMenu={centralControlPages.controlPanel.subMenu}
				id={centralControlPages.controlPanel.id}
			/>
		),
	},

	{
		path: `${dashboardMenu.digitalTwin.path}`,
		element: null,
	},
	{
		path: `${centralControlPages.systemManagement.path}/*`,
		element: <SystemManagementHeader_centralControl />,
	},
];

// 系统管理
const systemAppointment = [
	{
		path: `${systemLayoutPages.systemManagement.path}/*`,
		element: (
			<ProgramManagementHeader_media
				subMenu={systemLayoutPages.systemManagement.subMenu}
				id={systemLayoutPages.systemManagement.id}
			/>
		),
	},
];

const contents = [
	...headers,
	// ...media,
	// ...meeting,
	// ...pipeGallery,
	// ...energyConsumption,
	// ...centralControl,
	// ...systemAppointment,
	{
		path: `*`,
		element: <DefaultHeader />,
	},
];

export default contents;
