import requestKey from "../utils/secretKey";
import Config from "../config";
import request from "../utils/noVoucher";
const server = Config.siteAddress;

const on_off:any = Config.on_off ? Config.on_off : false;


export async function getSchedule() {
	return requestKey({
		url: 'https://echarts.apache.org//examples/data/asset/data/airport-schedule.json',
		method: 'get'
	});
}




// =============================用户管理=================================
export async function getUserKey(query: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_admin?database=zuad_common&include=id,username,real_name,englishName,nickname,password,org_num,portrait,groupid,department_id',
		method: 'get',
		params: query,
	});
}

export async function addUserKey(query: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_admin?database=zuad_common',
		method: 'post',
		data: query,
		headers: {
			'Content-Type': "application/json", // 关键
		},
	});
}

export async function editUserKey(id:number,data: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_admin/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "PUT",
		data,
		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

export async function delUserKey(id: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_admin/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
	});
}


// ===========================部门管理======================================
export async function getDepartmentKey(query: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_department?database=zuad_common',
		method: 'get',
		params: query,
	});
}

export async function addDepartmentKey(query: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_department?database=zuad_common',
		method: 'post',
		data: query,
		headers: {
			'Content-Type': "application/json", // 关键
		},
	});
}

export async function editDepartmentKey(id:any,data: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_department/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "PUT",
		data,
		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

export async function delDepartmentKey(id: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_department/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
	});
}


// =============================菜单管理===================================
export async function getAuthRuleKey(query: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_auth_rule?database=zuad_common',
		method: 'get',
		params: query,
	});
}

export async function addAuthRuleKey(query: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_auth_rule?database=zuad_common',
		method: 'post',
		data: query,
		headers: {
			'Content-Type': "application/json", // 关键
		},
	});
}

export async function editAuthRuleKey(id:number,data: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_auth_rule/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "PUT",
		data,
		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

export async function delAuthRuleKey(id: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_auth_rule/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
	});
}

// ===========================日志管理=====================================
export async function getOperateLogKey(query: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_operation_log?database=zuad_common',
		method: 'get',
		params: query,
	});
}

export async function addOperateLogKey(query: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_operation_log?database=zuad_common',
		method: 'post',
		data: query,
		headers: {
			'Content-Type': "application/json", // 关键
		},
	});
}

export async function editOperateLogKey(id:number,data: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_operation_log/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "PUT",
		data,
		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

export async function delOperateLogKey(id: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_operation_log/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
	});
}



// ===========================事件管理=====================================
export async function getEventLogKey(query: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_event?database=zuad_common',
		method: 'get',
		params: query,
	});
}

export async function addEventLogKey(query: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_event?database=zuad_common',
		method: 'post',
		data: query,
		headers: {
			'Content-Type': "application/json", // 关键
		},
	});
}

export async function editEventLogKey(id:number,data: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_event/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "PUT",
		data,
		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

export async function delEventLogKey(id: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_event/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
	});
}


// ===========================组织管理======================================
export async function getOrganizationKey(query: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_organization?database=zuad_common',
		method: 'get',
		params: query,
	});
}

export async function addOrganizationKey(query: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_organization?database=zuad_common',
		method: 'post',
		data: query,
		headers: {
			'Content-Type': "application/json", // 关键
		},
	});
}

export async function editOrganizationKey(id:number,data: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_organization/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "PUT",
		data,
		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

export async function delOrganizationKey(id: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_organization/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
	});
}

// ===========================角色管理======================================
export async function getAuthGroupKey(query: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_auth_group?database=zuad_common',
		method: 'get',
		params: query,
	});
}

export async function addAuthGroupKey(query: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_auth_group?database=zuad_common',
		method: 'post',
		data: query,
		headers: {
			'Content-Type': "application/json", // 关键
		},
	});
}

export async function editAuthGroupKey(id:number,data: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_auth_group/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "PUT",
		data,
		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

export async function delAuthGroupKey(id: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_auth_group/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
	});
}

// ===========================系统版本管理======================================
export async function getSystemVersionKey(query: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_system_version?database=zuad_common',
		method: 'get',
		params: query,
	});
}

export async function addSystemVersionKey(query: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_system_version?database=zuad_common',
		method: 'post',
		data: query,
		headers: {
			'Content-Type': "application/json", // 关键
		},
	});
}

export async function editSystemVersionKey(id:number,data: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_system_version/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "PUT",
		data,
		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

export async function delSystemVersionKey(id: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_system_version/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
	});
}


// ===========================授权管理======================================
export async function getEmpowerKey(query: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_empower?database=zuad_common',
		method: 'get',
		params: query,
	});
}

export async function addEmpowerKey(query: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_empower?database=zuad_common',
		method: 'post',
		data: query,
		headers: {
			'Content-Type': "application/json", // 关键
		},
	});
}

export async function editEmpowerKey(id:number,data: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_empower/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "PUT",
		data,
		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

export async function delEmpowerKey(id: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_empower/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
	});
}


// ===========================登录管理=====================================
export async function doLoginKey(query: any) {
	return requestKey({
		url: server + '/apikey.php/login',
		method: 'post',
		data: query,
		headers: {
			'Content-Type': "application/json", // 关键
		},
	});
}



// ===========================修改密码=====================================
export async function editPasswordKey(query: any) {
	return requestKey({
		url: server + '/apikey.php/password',
		method: 'post',
		data: query,
		headers: {
			'Content-Type': "application/json", // 关键
		},
	});
}



export async function getAuthRuleActivateKey(query: any) {
	return requestKey({
		url: Config.remoteServices + '/apikey.php/records/think_auth_rule?database=zuad_common',
		method: 'get',
		params: query,
	});
}
// ==========================会议区域===============================
export function getArea(query: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_area?database=meeting_dingding',
		method: 'get',
		params: query,
		headers: {
			'Content-Type': "application/json", // 关键
		},
	})
}

export async function addArea(query: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_area',
		method: 'post',
		data: query,
		params: {
			database: "meeting_dingding"
		},
		headers: {
			'Content-Type': "application/json", // 关键
		},
	});
}
// ==========================会议室===============================
export function getRoom(query: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_room?database=meeting_dingding',
		method: 'get',
		params: query,
		headers: {
			'Content-Type': "application/json", // 关键
		},
	})
}

export async function addRoom(query: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_room',
		method: 'post',
		data: query,
		params: {
			database: "meeting_dingding"
		},
		headers: {
			'Content-Type': "application/json", // 关键
		},
	});
}

// ==========================墨水屏模板===============================
export function getTemplate(query: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_template?database=meeting_dingding',
		method: 'get',
		params: query,
		headers: {
			'Content-Type': "application/json", // 关键
		},
	})
}

export async function addTemplate(query: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_template',
		method: 'post',
		data: query,
		params: {
			database: "meeting_dingding"
		},
		headers: {
			'Content-Type': "application/json", // 关键
		},
	});
}
// ===========================部门管理======================================
export async function getDepartment(query: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_department?database=zuad_common',
		method: 'get',
		params: query,
	});
}

export async function addDepartment(query: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_department?database=zuad_common',
		method: 'post',
		data: query,
		headers: {
			'Content-Type': "application/json", // 关键
		},
	});
}

export async function editDepartment(id: any, data: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_department/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "PUT",
		data,

		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

export async function delDepartment(id: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_department/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
	});
}
// ==========================会议显示模板===============================
export function getShow(query: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_show?database=meeting_dingding',
		method: 'get',
		params: query,
		headers: {
			'Content-Type': "application/json", // 关键
		},
	})
}

export async function addShow(query: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_show',
		method: 'post',
		data: query,
		params: {
			database: "meeting_dingding"
		},
		headers: {
			'Content-Type': "application/json", // 关键
		},
	});
}

// ==========================邮箱配置===============================
export function getVariablesKey(query: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_variables?database=meeting_dingding',
		method: 'get',
		params: query,
		headers: {
			'Content-Type': "application/json", // 关键
		},
	})
}

export async function addVariablesKey(query: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_variables',
		method: 'post',
		data: query,
		params: {
			database: "meeting_dingding"
		},
		headers: {
			'Content-Type': "application/json", // 关键
		},
	});
}
// ==========================系统配置===============================
export function getConfigKey(query: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_config?database=zuad_common',
		method: 'get',
		params: query,
		headers: {
			'Content-Type': "application/json", // 关键
			"X-API-Key": "02c042aa-c3c2-4d11-9dae-1a6e230ea95e"
		},
	})
}

export async function addConfigKey(query: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_config?database=zuad_common',
		method: 'post',
		data: query,
		headers: {
			'Content-Type': "application/json", // 关键
		},
	});
}

export async function editConfigKey(id: number, data: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_config/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "PUT",
		data,

		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

export async function delConfig(id: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_config/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
	});
}

// 编辑配置
export async function editConfigz(data: any) {
	return requestKey({
		url: server + '/apikey.php/sendpost',
		method: 'post',
		data,
		headers: {
			'Content-Type': 'multipart/form-data', // 关键
		},
	})
}
// 新增配置
export async function addConfigs(data: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_configs',
		method: 'post',
		data,
		params: {
			database: "zuad_common"
		}
	})
}
// 编辑配置
export async function editConfigs(data: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_configs/' + data.id,
		method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
		data,
		params: {
			database: "zuad_common"
		}
	})
}

//=========================流量池===========================================
export async function getFlowPoolKey(query: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_flow_pool?database=zuad_common&join=think_organization',
		method: 'get',
		params: query,
	});
}

export async function addFlowPoolKey(query: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_flow_pool?database=zuad_common',
		method: 'post',
		data: query,
		headers: {
			'Content-Type': "application/json", // 关键
		},
	});
}

export async function editFlowPoolKey(id:number,data: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_flow_pool/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "PUT",
		data,
		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

export async function delFlowPoolKey(id: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_flow_pool/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "DELETE",
		headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
	});
}

