import React from 'react';
import {demoPages, layoutMenu, mediaPages} from '../menu';
import DefaultFooter from '../pages/_layout/_footers/DefaultFooter';

const footers = [
	{ path: layoutMenu.blank.path, element: null, exact: true },
	{ path: layoutMenu.excelMap.path, element: null, exact: true },
	{ path: demoPages.generateKeyPage.path, element: null, exact: true },
	{ path: demoPages.login.path, element: null, exact: true },
	{ path: demoPages.signUp.path, element: null, exact: true },
	{ path: demoPages.page404.path, element: null, exact: true },
	{ path: demoPages.knowledge.subMenu.grid.path, element: null, exact: true },
	// { path: mediaPages.systemManagement.subMenu.operateRoleManagement.path + "/:powerId", element: null, exact: true },
	{ path: 'paperless/meetingList/_details/meetingDetails', element: null, exact: true},
	{ path: 'paperless/meetingList/_details/meetingDetail/:id', element: null, exact: true },
	{ path: 'paperless/meetingList/_details/participants/:id', element: null, exact: true },
	{ path: 'paperless/meetingList/_details/addParticipants/:id', element: null, exact: true },
	{ path: 'paperless/meetingList/_details/annotationRecord/:id', element: null, exact: true },
	{ path: 'paperless/meetingList/_details/fileManagement/:id', element: null, exact: true },
	{ path: 'paperless/meetingList/_details/videoManagement/:id', element: null, exact: true },
	{ path: 'paperless/meetingList/_details/votingManagement/:id', element: null, exact: true },
	{ path: 'paperless/meetingList/_details/votingDetailedManagement/:id', element: null, exact: true },
	{ path: 'paperless/meetingList/_details/votingGroupDetailedManagement/:id', element: null, exact: true },
	{ path: 'paperless/meetingList/_details/voiceSummary/:id', element: null, exact: true },
	{ path: 'PdfPreview/:fileMd5', element: null, exact: true },
	{ path: 'paperless/meetingList/votingStatistics/:id', element: null, exact: true },
	{ path: mediaPages.mobilePhoneScanModel.subMenu.comprehensiveList.path, element: null, exact: true },
	{
		path: `${demoPages.empowerPage.path}`, element: null, exact: true
	},
	{
		path: `${mediaPages.systemManagement.subMenu.addOrganization.path}/:orgNumId`,
		element: null,
		exact: true,
	},
	{
		path: `${mediaPages.systemManagement.subMenu.addOrganizationDetails.path}/:orgNumId`,
		element: null,
		exact: true,
	},

	{
		path: `${demoPages.editPassword.path}/:username`, element: null, exact: true
	},

	{
		path: 'paperless/meetingList/_details/votingGroupManagement/:id',
		element: null,
		exact: true,
	},
	{
		path: 'paperless/meetingList/_details/informationManagement/:id',
		element: null,
		exact: true,
	},
	{ path: 'paperless/meetingList/_details/conferenceRecords/:id', element: null, exact: true },
	{ path: 'paperless/meetingList/_details/seatMapManagement/:id', element: null, exact: true },
	{ path: 'paperless/meetingList/_details/seatMapArrangement/:id', element: null, exact: true },
	{ path: 'paperless/systemSettings/meetingRoomImg', element: null, exact: true},
	{ path: 'paperless/systemSettings/addPermission', element: null, exact: true},
	{ path: 'check-in', element: null, exact: true },
	{ path: 'callback', element: null, exact: true },
	{ path: '*', element: <DefaultFooter /> },
];

export default footers;
