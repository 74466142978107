import React, { useLayoutEffect, forwardRef, ReactElement, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useNavigate, useLocation } from 'react-router-dom';
import AuthContext from '../../contexts/authContext';
import {demoPages, layoutMenu, mediaPages} from '../../menu';
import { ISubHeaderProps } from "../SubHeader/SubHeader";
import { IPageProps } from "../Page/Page";
import {InitConfig} from "../../api/common";
import Config from "../../config";
import { setStorages, getStorages } from "../../utils/localStorage";

import { ILang } from "../../lang";
import i18n from "../../i18n";
import { inArrayVague} from "../../utils";

interface IPageWrapperProps {
	isProtected?: boolean;
	title?: string;
	description?: string;
	children:
	| ReactElement<ISubHeaderProps>[]
	| ReactElement<IPageProps>
	| ReactElement<IPageProps>[];
	className?: string;
}

const PageWrapper = forwardRef<HTMLDivElement, IPageWrapperProps>(
	({
		isProtected = true,
		description,
		className,
		children
	}: IPageWrapperProps, ref) => {
		const { userData, user } = useContext(AuthContext);

		const [ configFind, setConfigFind] = useState<any>(null)
		useEffect(() => {
			const systemConfig = getStorages("systemConfig")
			setConfigFind(systemConfig !== "" ? JSON.parse(systemConfig) : InitConfig())

			// eslint-disable-next-line react-hooks/exhaustive-deps
		},[userData])


		useLayoutEffect(() => {
			if (configFind) {
				document.getElementsByTagName('title')[0].text = (i18n.language as ILang['key']['lng'] === "zh_CN") ? (
					configFind.web_site_title ?? "智能综合管理系统"
				) : (
					configFind.web_site_title_en ?? "System"
				);


				//设置-页面标签小图标
				if (Number(configFind?.web_favicon_logo_show ?? 0)) {
					const web_favicon_logo = Config.siteAddress + "/" + configFind.web_favicon_logo
					// @ts-ignore
					document?.querySelector('link[rel="apple-touch-icon"]').setAttribute('href', web_favicon_logo);

					// @ts-ignore
					document?.querySelector('link[rel="icon"]').setAttribute('href', web_favicon_logo);
				}

			}

			// @ts-ignore
			document?.querySelector('meta[name="description"]').setAttribute('content', description || process.env.REACT_APP_META_DESC || '');
		});

		const navigate = useNavigate();
		const location = useLocation();
		useEffect(() => {
			const pathname = location.pathname;

			// 需要过滤的菜单
			const filter_menu = [
				demoPages.login.path,
				layoutMenu.excelMap.path,
				mediaPages.systemManagement.subMenu.addOrganization.path,
				mediaPages.systemManagement.subMenu.addOrganizationDetails.path,
				"/check-in",
				"/callback"
			];
			if (isProtected && !user && !inArrayVague(pathname, filter_menu)) {
				// eslint-disable-next-line eqeqeq
				if (getStorages('callbackCode') != undefined && getStorages('callbackCode') != '' && getStorages('login') == undefined) {
					setStorages('callbackCode', "");
					let userAgent = navigator.userAgent;
					if (userAgent.indexOf('Firefox') !== -1 || userAgent.indexOf('Chrome') !== -1) {
						window.location.replace('about:blank');
					} else {
						window.opener = null;
						window.open('', '_self');
					}
					window.close();
				} else if (getStorages('callbackCode') !== undefined && getStorages('callbackCode') !== '' && getStorages('login') !== undefined) {

				} else {
					navigate(`${demoPages.login.path}`);
				}

			} else if (user && pathname === `${demoPages.login.path}`) {
				navigate(`/`);
			}



		}, [isProtected, user, navigate, location.pathname]);

		return (
			<div ref={ref} className={classNames('page-wrapper', 'container-fluid', className)}>
				{children}
			</div>
		);
	},
)

PageWrapper.displayName = 'PageWrapper';
PageWrapper.propTypes = {
	isProtected: PropTypes.bool,
	title: PropTypes.string,
	description: PropTypes.string,
	// @ts-ignore
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};

export default PageWrapper;
